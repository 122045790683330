import React, { useEffect, useState } from "react";
import { Button, Modal, List, Input, Image, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { getProductList } from "@/apis/activity";

import "./index.less";

const HeaderCell = [
  {
    title: '封面',
    dataIndex: 'cover',
    align: 'center',
    render: (value) => {
      return (
        <Image src={value} width={40} height={40} />
      )
    }
  },
  {
    title: '商品名称',
    dataIndex: 'product_name',
    align: 'center',
    render: (value) => {
      return (
        <div className="ell1" title={value}>{value}</div>
      )
    }
  },
  {
    title: '销售价',
    dataIndex: 'price',
    align: 'center',
    width: 85
  },
  {
    title: '划线价',
    dataIndex: 'first_price',
    align: 'center',
    width: 85
  }
]
const limit = 10

function ProductSelector2(props) {
  const { value, onChange } = props;
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0)
  const [selData, setSelData] = useState([]);
  const [dataSoruce, setDatasoruce] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [name, setName] = useState();

  useEffect(() => {
    getList()
  }, [name, page]);

  useEffect(() => {
    if (value) {
      setSelData(value);
      setSelectedRowKeys(value.map(t => t.product_id))
    }
  }, [value]);

  const getList = async() => {
    try {
      const { data } = await getProductList({
        page,
        limit: limit,
        keywords: name
      })
      setDatasoruce(data.data);
      setTotal(data.total)
    } catch (e) {
      console.log(e)
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    const oldList = selData.filter(t => dataSoruce.every(_ => _.product_id != t.product_id))
    const list = dataSoruce.filter(t => newSelectedRowKeys.includes(t.product_id)).map(t => ({
      product_id: t.product_id,
      product_name: t.product_name,
      store_id: t.store_id
    }))
    const v = [...oldList, ...list]
    setSelData(v)
    setSelectedRowKeys(v.map(t => t.product_id))
  }

  return (
    <div className="Product-selector">
      <Button type="primary" onClick={() => setVisible(true)}>
        选择
      </Button>
      <Modal
        title="选择商品"
        open={visible}
        onOk={() => {
          onChange(selData);
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
        okText="确认"
        cancelText="取消"
      >
        <Input.Search
          placeholder="输入商品名称或者ID"
          enterButton="搜索"
          size="large"
          allowClear
          onSearch={(v) => {
            setPage(1);
            setName(v);
          }}
          style={{
            marginBottom: 16
          }}
        />
        <Table
          rowKey="product_id"
          columns={HeaderCell}
          dataSource={dataSoruce}
          bordered={true}
          size="small"
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange
          }}
          pagination={{
            current: page,
            total: total,
            defaultPageSize: limit,
            showSizeChanger: false,
            showQuickJumper: true,
            onChange: (v) => setPage(v),
            showTotal: (total) => `共${total}条`,
            style: {
              marginBottom: 0
            }
          }}
        />
      </Modal>
      {value && value.length ? (
        <List
          style={{
            maxWidth: 300,
          }}
        >
          {value.map((item, index) => (
            <List.Item
              key={item.product_id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: 10,
                }}
              >
                商品ID：{item.product_id}
              </span>
              {/* <img
                    src={item.cover.url}
                    alt=""
                    width="50"
                    height="50"
                    style={{
                      margin: "0 10px",
                    }}
                  /> */}
              <span>商品名称：{item.product_name}</span>
              <Button
                type="link"
                onClick={() => {
                  const _value = [...value]
                  _value.splice(index, 1);
                  onChange(_value);
                }}
              >
                <DeleteOutlined />
              </Button>
            </List.Item>
          ))}
        </List>
      ) : null}
    </div>
  );
}

export default ProductSelector2;
