import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, Image, message, Modal, Space, Tag } from 'antd'
import ProductSelector2 from '@/components/productSelector2'
import qs from 'query-string'
import { getActivityDetail, getStoreJoin } from '@/apis/activity'
import './index.less'

const ActivityDetail = (props) => {
    const { history } = props;
    const { id, is_join } = qs.parse(history.location.search)
    const [form] = Form.useForm()
    const [info, setInfo] = useState({})
    const [showPreview, setShowPreview] = useState(false)

    useEffect(() => {
        if (id) {
            getData()
        }
    }, [])

    const isJoin = useMemo(() => {
        if (info.id) {
            return is_join == 1 && info.status == 3 && info.is_self_publish == 0 && info.is_join == 1 && info.store_is_join == 0
        }
        return false
    }, [info])

    const getData = async() => {
        try {
            const { data } = await getActivityDetail({ id })
            setInfo(data)
        } catch (e) {
            console.log(e)
        }
    }

    const onSubmit = async(values) => {
        try {
            const { data } = await getStoreJoin({
                id: id,
                join_goods: JSON.stringify(values.join_goods)
            })
            message.success('加入成功');
            getData()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="activityDetail">
            <Form form={form} colon={false} autoComplete="off" onFinish={onSubmit}>
                <Form.Item label="活动封面">
                    <div>
                        <Image
                            src={info.cover}
                            style={{
                                width: 100,
                                height: 100
                            }}
                        />
                    </div>
                </Form.Item>
                {!!info.video_url && <Form.Item label="活动视频">
                    <div>
                        <Image
                            src={info.video_url+'?x-oss-process=video/snapshot,t_1050,f_jpg'}
                            preview={false}
                            style={{
                                width: 100,
                                height: 100
                            }}
                            onClick={() => setShowPreview(true)}
                        />
                    </div>
                </Form.Item>}
                <Form.Item label="活动名称">
                    <div style={{ width: 300 }}>{info.title}</div>
                </Form.Item>
                <Form.Item label="活动介绍">
                    <div style={{ width: 300 }}>{info.des}</div>
                </Form.Item>
                <Form.Item label="活动类型">
                    <div style={{ width: 300 }}>{info.type_name}</div>
                </Form.Item>
                <Form.Item label="活动时间">
                    <Space>
                        <div>{info.start_time}</div>
                        <div>-</div>
                        <div>{info.end_time}</div>
                    </Space>
                </Form.Item>
                <Form.Item label="活动设置">
                    <div>
                        {info.activity_coupon_list && info.activity_coupon_list.map(t => (<Tag key={t.numeral_coupon_id} color="processing">{t.numeral_coupon_name}</Tag>))}
                    </div>
                </Form.Item>
                {info.activity_product_list?.length > 0 && <Form.Item label="活动商品">
                    <div>
                        {info.activity_product_list.map(t => (<Tag key={t.product_id} color="processing">{t.product_name}</Tag>))}
                    </div>
                </Form.Item>}
                {isJoin && <Form.Item
                    label="选择商品"
                    name="join_goods"
                    initialValue={[]}
                    rules={[{ required: true, message: '请选择商品' }]}
                >
                    <ProductSelector2 mode="multiple" />
                </Form.Item>}
                <Form.Item label="活动商家">
                    <Space>
                        {info.is_self_publish === 0 && <div>{info.store_name || '官方'}</div>}
                        <div>
                            {info.activity_store_list && info.activity_store_list.map(t => (<Tag key={t.store_id} color="processing">{t.store_name}</Tag>))}
                        </div>
                    </Space>
                </Form.Item>
                <Form.Item label=" ">
                    <Button
                        onClick={() => window.history.back()}
                        style={{
                            marginRight: 10,
                        }}
                    >
                        返回
                    </Button>
                    {isJoin && <Button type="primary" htmlType="submit">
                        加入活动
                    </Button>}
                </Form.Item>
            </Form>
            <Modal
                open={showPreview}
                title="活动视频"
                footer={null}
                onCancel={() => {
                    setShowPreview(false);
                }}
            >
                <video
                    controls="controls"
                    style={{ width: "100%" }}
                    src={info.video_url}
                />
            </Modal>
        </div>
    )
}

export default ActivityDetail