import React, { useState, useEffect } from "react";
import { Input, Radio, Message } from "antd";
import UploadImg from "@/components/store/uploadImg";
import IndexImg from "@/components/store/indexImg";
import Contact from "@/components/public/contact/contact";
import Address from "@/components/store/address";
import Centers from "@/components/public/contact/center";
import BusinessImg from "@/components/FileUpload";
import Client from "@/components/public/contact/client";
import Information from "@/components/public/contact/information";
import {
  handleImg,
  checkSocialCreditCode,
  isCardNo,
  operateImg,
  verification,
} from "@/utils/index";
import { isSocialCreditCode } from "@/utils/regexp";
import "./index.less";
import {
  STORE_INFO,
  ADD_STORE,
  CHANGE_STORE,
  IMAGE2TEXT,
} from "../../graphql/store";
import client from "../../graphql/apollo-config";
import app from "../../app.json";
import { isEmpty } from "../../utils";
import { Modal } from "antd/lib/index";

const axios = require("axios").default;

const { TextArea } = Input;

const Index = (props) => {
  const [OSSData, setOSSData] = useState({});
  const [images, setImages] = useState([]); // 商家主图
  const [cover, setCover] = useState([]); // 商家主图
  const [video, setVideo] = useState([]); // 商家视频
  const [businessLicense, setBusinessLicense] = useState([]); // 营业执照
  const [first, setFirst] = useState(true); // 门店是否首次
  const [checkLicense, setLicense] = useState(false); // 是否能填
  const [checkCode, setCode] = useState(false); // 是否能填
  const [firstAudit, setFirstAudit] = useState(true); // 基本信息是否首次
  const [settleStatus, setSettleStatus] = useState(null); // 入驻方式
  const [storeInfo, setStoreInfo] = useState({
    product_type: null,
    open_status: null,
    images: [],
    cover: null,
    video: null,
    lowest_delivery_price: null,
    average_spend: null,
    notice: "",
    credit_code: null, // 信用代码
    check_can_open: 0,
  });
  const [otherInfo, setOtherInfo] = useState({
    province: "",
    city: "",
    area: "",
    address: "", // 店铺地址
    hot_line: null, // 客服手机
  });
  const [status, setStatus] = useState(null);
  const [rText, setRText] = useState("");
  const [rejectReason, setRejectReason] = useState("");

  const history = props.history;

  const rule1 = [
    {
      rule: storeInfo.product_type !== 0 && storeInfo.product_type !== 1,
      title: "请选择店铺类型",
    },
    {
      rule: !storeInfo.average_spend,
      title: "请填写人均消费",
    },
    {
      rule: (storeInfo.average_spend + "").indexOf(".") != -1,
      title: "人均消费不能为小数",
    },
    {
      rule:
        storeInfo.product_type == 0 && storeInfo.lowest_delivery_price === "",
      title: "请填写起送价",
    },
    {
      rule:
        storeInfo.product_type == 0 &&
        (storeInfo.lowest_delivery_price + "").indexOf(".") != -1,
      title: "起送价不能为小数",
    },
    {
      rule: !otherInfo.province,
      title: "请选择省份",
    },
    {
      rule: !otherInfo.city,
      title: "请选择市",
    },
    {
      rule: !otherInfo.area,
      title: "请选择区/县",
    },
    {
      rule: !otherInfo.address,
      title: "请填写详细地址",
    },
    {
      rule: otherInfo.address && otherInfo.address.length < 5,
      title: "请填写5个字以上的详细地址",
    },
    {
      rule: !otherInfo.hot_line,
      title: "请填写客服手机",
    },
    {
      rule:
        otherInfo.hot_line &&
        (otherInfo.hot_line.length !== 11 || otherInfo.hot_line[0] != 1),
      title: "客服手机号码格式不正确",
    },
    {
      rule: cover.length < 1,
      title: "请上传店铺头像",
    },
    {
      rule: images.length < 1,
      title: "请上传店铺主图",
    },
    {
      rule: storeInfo.notice && storeInfo.notice.length > 100,
      title: "店铺公告不能超过100个字",
    },
    // {
    //   rule: settleStatus == 1 && JSON.stringify(businessLicense) != '[]' && !storeInfo.credit_code,
    //   title: "请填写统一社会信用代码"
    // },
    // {
    //   rule: settleStatus == 1 && storeInfo.credit_code && storeInfo.credit_code.length < 18,
    //   title: "统一社会信用代码必须18位"
    // },
    // {
    //   rule: settleStatus == 1 && storeInfo.credit_code && !isSocialCreditCode(storeInfo.credit_code),
    //   title: "统一社会信用代码不正确"
    // },
    // {
    //   rule: settleStatus == 1 && storeInfo.credit_code && JSON.stringify(businessLicense) == '[]' ,
    //   title: "请上传营业执照"
    // },
  ];

  useEffect(() => {
    getStoreInfo();
  }, []);

  const getStoreInfo = async () => {
    try {
      const { data } = await client.query({
        query: STORE_INFO,
        fetchPolicy: "no-cache",
      });
      setOSSData({
        ...data.oss,
      });
      if (data.storeInfo) {
        const isFirst = data.storeInfo.is_first_store_audit;
        const store_audit = isFirst
          ? data.storeInfo.store_audit
          : data.storeInfo;
        if (data.storeInfo.store_audit) {
          const storeAuditData = data.storeInfo.store_audit;
          if (storeAuditData.status === 0 || storeAuditData.status === 3) {
            setRText("审核中");
          } else if (storeAuditData.status === 1) {
            setRText("审核通过");
          } else if (storeAuditData.status === 2) {
            setRText("审核不通过");
            setRejectReason(storeAuditData.reject_reason);
          }
          setStatus(storeAuditData.status);
          console.log(data.storeInfo.shop_id, 999999);
          setLicense(
            store_audit.business_license &&
              (storeAuditData.status === 1 || data.storeInfo.shop_id !== 0)
          );
          setCode(
            store_audit.credit_code &&
              (storeAuditData.status === 1 || data.storeInfo.shop_id !== 0)
          );
        }
        setSettleStatus(data.storeInfo.settle_status);
        setFirst(isFirst);
        if (data.storeInfo.store_base_audit) {
          setFirstAudit(data.storeInfo.store_base_audit.is_first_audit);
        } else {
          setFirstAudit(data.storeInfo.is_ushoufu == 0);
        }
        setOtherInfo({
          ...otherInfo,
          hot_line: data.storeInfo.hot_line,
          province: data.storeInfo.province,
          city: data.storeInfo.city,
          area: data.storeInfo.area,
          address: data.storeInfo.address,
        });
        if (store_audit) {
          setStoreInfo({
            ...store_audit,
          });
          setBusinessLicense(
            imgData(
              store_audit.business_license && store_audit.business_license.url,
              "business_license"
            )
          );
          // 处理图片
          if (store_audit.images.length > 0) {
            let a = store_audit.images;
            a.map((i, index) => {
              i.uid = index;
              i.name = new Date().getTime();
              return a;
            });
            setImages([...a]);
          }
          // 给视频加id
          if (store_audit.video) {
            operateImg(
              store_audit.video.url,
              store_audit.video.url +
                "?x-oss-process=video/snapshot,t_1000,f_jpg",
              setVideo
            );
          }
          // 头像
          if (store_audit.cover) {
            operateImg(store_audit.cover.url, "", setCover);
          }
        }
      }
    } catch (error) {}
  };

  const imgData = (url, name) => {
    if (url) {
      let c = {};
      c.url = url;
      c.uid = name;
      c.name = name;
      return [c];
    } else {
      return [];
    }
  };

  // 修改数据
  const changeData = (name, value) => {
    console.log(value, 999);
    setStoreInfo({
      ...storeInfo,
      [name]: value,
    });
  };

  const image2Text = async (arr) => {
    try {
      if (arr && arr.length > 0) {
        const { data } = await client.query({
          query: IMAGE2TEXT,
          variables: {
            type: 2,
            url: handleImg(arr),
            side: "",
          },
          fetchPolicy: "no-cache",
        });
        if (data && data.image2Text) {
          const credit_code = data.image2Text.credit_code;
          setBusinessLicense(arr);
          setStoreInfo({
            ...storeInfo,
            credit_code:
              !isEmpty(credit_code) && credit_code != "无" ? credit_code : "",
          });
        }
      }
    } catch (error) {}
  };

  const submitMutate = async (lng, lat) => {
    // 上传前处理图片格式
    let imgs = [];
    images.map((i) => {
      imgs.push(i.url.replace(`${app.oss_host}`, ""));
    });
    try {
      const { data } = await client.mutate({
        mutation: ADD_STORE,
        variables: {
          product_type: storeInfo.product_type,
          lowest_delivery_price: storeInfo.lowest_delivery_price
            ? storeInfo.lowest_delivery_price
            : 0,
          average_spend: storeInfo.average_spend,
          cover: handleImg(cover),
          images: imgs,
          province: otherInfo.province,
          city: otherInfo.city,
          area: otherInfo.area,
          address: otherInfo.address,
          lng: lng,
          lat: lat,
          business_license: handleImg(businessLicense)
            ? handleImg(businessLicense)
            : "",
          credit_code: storeInfo.credit_code ? storeInfo.credit_code : "",
          hot_line: otherInfo.hot_line,
          video: handleImg(video),
          notice: storeInfo.notice,
        },
        fetchPolicy: "no-cache",
      });
      if (data.addStore) {
        Message.success("提交成功,请等待审核");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {}
  };

  const submit = async () => {
    if (status == 0 || status == 3) {
      Message.info("店铺正在审核中，请勿重复提交");
    } else {
      if (!verification(rule1)) return;
      const { data } = await axios.get(
        `https://restapi.amap.com/v3/geocode/geo?key=75060fae7d3d453c790b609a312a7a08&address=${
          otherInfo.city + otherInfo.area + otherInfo.address
        }&city=${otherInfo.city}`
      );
      const d = data.geocodes[0].location.indexOf(",");
      const lng = data.geocodes[0].location.slice(0, d);
      const lat = data.geocodes[0].location.slice(d + 1);
      submitMutate(lng, lat);
    }
  };

  const changeList = (v, i) => {
    if (i == 1) {
      setOtherInfo({
        ...otherInfo,
        province: v,
        city: "",
        area: "",
      });
    } else {
      setOtherInfo({
        ...otherInfo,
        city: v,
        area: "",
      });
    }
  };

  const showExample = (img) => {
    Modal.info({
      icon: null,
      content: (
        <img
          style={{ textAlign: "center", width: "530px" }}
          src={require(`../../assets/images/example/${img}.jpg`)}
          alt=""
        />
      ),
      width: 600,
    });
  };

  const changeStore = async (e) => {
    if (storeInfo.check_can_open == 1) {
      Message.info("门店不允许营业，请联系客服");
    } else if (storeInfo.check_can_open == 2) {
      Message.info("请先上架商品");
    } else {
      // let s = storeInfo.open_status == 0?1:0
      try {
        const { data } = await client.mutate({
          mutation: CHANGE_STORE,
          variables: {
            open_status: e.target.value,
          },
          fetchPolicy: "no-cache",
        });
        if (data) {
          changeData("open_status", data.changeStore.open_status);
        }
      } catch (error) {}
    }
  };

  return (
    <div className="category-wrapper">
      <div style={{ flex: 1, marginRight: 40 }}>
        <div className="category-top">
          <div className="category-top_status">
            <span className="category-top_status-title">
              线上开店认证
              <span className="tip" style={{ marginLeft: "4px" }}>
                (认证成功后，可在典栈生活圈商城开店)
              </span>
            </span>
            <span className="red">{rText}</span>
            {rejectReason ? (
              <span className="rej">理由：{rejectReason}</span>
            ) : null}
          </div>
          {firstAudit && (
            <div
              className="category-top_status"
              style={{ color: "#FF0000", fontSize: "15px", marginTop: "0" }}
            >
              <span
                className="category-top_status-title"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img src={require("../../assets/images/tip.png")} alt="" />
                基本信息审核通过后可验证
              </span>
            </div>
          )}
          {!firstAudit && (
            <div className="category-top_first" style={{ marginTop: "26px" }}>
              <div className="category-top_first-title">店铺营业状态</div>
              <div className="content">
                <Radio.Group
                  onChange={(e) => changeStore(e)}
                  value={storeInfo.open_status}
                >
                  <Radio style={{ fontSize: 16, marginRight: 30 }} value={1}>
                    店铺营业中
                  </Radio>
                  <Radio style={{ fontSize: 16 }} value={0}>
                    店铺关闭
                    <span className="tip">店铺关闭时，用户将搜索不到店铺</span>
                  </Radio>
                </Radio.Group>
              </div>
              {/*<Switch onClick={()=>changeStore()} checkedChildren="开" unCheckedChildren="关" checked={storeInfo.open_status == 1}/>*/}
            </div>
          )}
        </div>
        {!firstAudit && (
          <div>
            <div className="category-content">
              <div
                className="category-content_wrapper"
                style={{ alignItems: "flex-start" }}
              >
                <div className="title">
                  店铺类型 <i className="red">*</i>
                </div>
                <div className="content">
                  <Radio.Group
                    onChange={(e) => {
                      changeData("product_type", e.target.value);
                    }}
                    value={storeInfo.product_type}
                    disabled={!first}
                  >
                    <Radio style={{ marginBottom: 28 }} value={0}>
                      实物商品（需配送发货）
                    </Radio>
                    <Radio value={1}>
                      电子卡券（系统自动生成券码，线上核销）
                    </Radio>
                  </Radio.Group>
                  <div
                    className="tip2"
                    style={{
                      marginLeft: 24,
                      marginBottom: 0,
                      color: "#F4981D",
                    }}
                  >
                    温馨提示：商品类型认证成功后，不允许修改
                  </div>
                </div>
              </div>
              <div className="category-content_wrapper">
                <div className="title">
                  消费设置 <i className="red">*</i>
                </div>
                <div
                  className="title"
                  style={{ fontSize: 14, marginRight: 22 }}
                >
                  人均消费
                </div>
                <div className="content">
                  <Input
                    type="number"
                    value={storeInfo.average_spend}
                    suffix="元"
                    style={{ width: 120, marginRight: 80 }}
                    onChange={(e) =>
                      changeData("average_spend", e.target.value)
                    }
                  />
                </div>
                {storeInfo.product_type == 0 ? (
                  <div
                    className="title"
                    style={{ fontSize: 14, marginRight: 22 }}
                  >
                    起送价
                  </div>
                ) : null}
                {storeInfo.product_type == 0 ? (
                  <div className="content">
                    <Input
                      type="number"
                      value={storeInfo.lowest_delivery_price}
                      suffix="元"
                      style={{ width: 120, marginRight: 80 }}
                      onChange={(e) =>
                        changeData("lowest_delivery_price", e.target.value)
                      }
                    />
                  </div>
                ) : null}
              </div>
              <Address
                title={"店铺地址"}
                province={otherInfo.province}
                city={otherInfo.city}
                area={otherInfo.area}
                provinceName={"province"}
                cityName={"city"}
                areaName={"area"}
                address={otherInfo.address}
                showAddress={true}
                changeList={(v, i) => changeList(v, i)}
                changeData={(name, value) => {
                  setOtherInfo({
                    ...otherInfo,
                    [name]: value,
                  });
                }}
              />
              <div className="category-content_wrapper">
                <div className="title">
                  客服手机 <i className="red">*</i>
                </div>
                <div className="content">
                  <Input
                    style={{ width: 734 }}
                    type="text"
                    placeholder="请填写客服手机号"
                    value={otherInfo.hot_line}
                    className="my-input"
                    onChange={(e) => {
                      setOtherInfo({
                        ...otherInfo,
                        hot_line: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <IndexImg
                changePortrait={(arr) => {
                  setCover([...arr]);
                }}
                portrait={cover}
                OSSData={OSSData}
              />
              <div className="tip2">
                *建议上传140*140px或比例1：1，JPG、PNG格式，图片小于1M。
              </div>
              <UploadImg
                changeImages={(arr) => {
                  setImages([...arr]);
                }}
                changeVideo={(arr) => {
                  if (arr.length > 0) {
                    arr[0].thumbUrl =
                      arr[0].url + "?x-oss-process=video/snapshot,t_1000,f_jpg";
                  }
                  setVideo([...arr]);
                }}
                imagesList={images}
                storeVideo={video}
                OSSData={OSSData}
              />
              <div
                className="category-content_wrapper"
                style={{ alignItems: "flex-start" }}
              >
                <div className="title">
                  店铺公告 <i className="red"></i>
                </div>
                <div
                  className="content"
                  style={{ position: "relative", width: 732 }}
                >
                  <TextArea
                    style={{ width: 732, height: 85, fontSize: 14 }}
                    value={storeInfo.notice}
                    placeholder="选填，店铺公告可输入新店开业优惠信息、店铺促销信息、店铺服务承诺等"
                    maxLength={100}
                    onChange={(e) => changeData("notice", e.target.value)}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 6,
                      right: 10,
                      fontSize: 14,
                      color: "#999",
                    }}
                  >
                    {storeInfo.notice.length + "/100"}
                  </div>
                </div>
              </div>
            </div>
            {/*{*/}
            {/*settleStatus == 1 && storeInfo.product_type == 0 && <div className="category-content">*/}
            {/*<div style={{marginBottom:30,fontWeight:'bold'}}>以下资料开通蜂鸟同城配送时，必须填写</div>*/}
            {/*<div className="category-content_wrapper" style={{alignItems: 'flex-start'}}>*/}
            {/*<div className="title">*/}
            {/*营业执照 <i className="red"></i>*/}
            {/*</div>*/}
            {/*<BusinessImg*/}
            {/*OSSData={OSSData}*/}
            {/*listChange={(arr) => {*/}
            {/*setBusinessLicense(arr);*/}
            {/*}}*/}
            {/*onSuccess={(arr) => {*/}
            {/*image2Text(arr);*/}
            {/*}}*/}
            {/*fileList={businessLicense}*/}
            {/*disabled={checkLicense}*/}
            {/*/>*/}
            {/*<div className="img-tip">*/}
            {/*• 上传JPG、PNG格式，图片小于1M；*/}
            {/*<br />*/}
            {/*• 营业执照未过期；*/}
            {/*<br />*/}
            {/*• 需文字清晰、边框完整、露出国徽；*/}
            {/*<br />• 复印件需加盖公章。*/}
            {/*<p*/}
            {/*style={{ color: "#048ad3", cursor: "pointer" }}*/}
            {/*onClick={() => showExample(3)}*/}
            {/*>*/}
            {/*示例*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*<div className="category-content_wrapper">*/}
            {/*<div className="title">*/}
            {/*统一社会*/}
            {/*<br />*/}
            {/*信用代码 <i className="red"></i>*/}
            {/*</div>*/}
            {/*<div className="content">*/}
            {/*<Input*/}
            {/*style={{ width: 734 }}*/}
            {/*disabled={checkCode}*/}
            {/*placeholder="统一社会信用代码"*/}
            {/*value={storeInfo.credit_code}*/}
            {/*className="my-input"*/}
            {/*maxLength={18}*/}
            {/*onChange={(e) =>*/}
            {/*changeData("credit_code", e.target.value)*/}
            {/*}*/}
            {/*/>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*}*/}
            <div className="comfirm-btn" onClick={submit}>
              提交
            </div>
          </div>
        )}
      </div>
      <div className="index-contact">
        <Centers history={history} title={"公告通知"} type={3} />
        <Contact />
        <Information />
        <Client />
        <Centers history={history} title={"学习中心"} type={2} />
      </div>
    </div>
  );
};

export default Index;
