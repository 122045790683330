/*
 * @Author: Fan
 * @Date: 2020-12-15 15:47:31
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-22 16:05:50
 * @Description: file content
 */
import React, { useState, useEffect } from "react";
import {
  Radio,
  Switch,
  Button,
  TimePicker,
  message,
  Message,
  Form,
  Input,
  InputNumber,
  Space,
  Modal,
  Upload,
} from "antd";

import Cookies from "js-cookie";
import { useMainContext } from "../../utils/context";
import { MinusCircleOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Change_Store_Mutation,
  DELIVERY_WAY,
  BIND_PRINTER,
  CHANGE_STORE_INFO,
  UNBIND_PRINTER,
  CREATE_CUSTVICE,
  DEL_CUSTVICE,
  REBATE_RATE_SAVE,
  WECOM_QRCODE_SAVE,
  STORE_BACKGROUND_SAVE,
  STORE_CLIENT_SETTING,
} from "../../graphql/store";
import client from "../../graphql/apollo-config";
import moment from "moment";
import md5 from "md5";
import app from "../../app.json";
import ImgCrop from "antd-img-crop";
import { getBase64 } from "@/utils";

import "./index.less";

// 上传验证
const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图像必须小于2MB!');
  }
  return isLt2M;
};

const Logistics = () => {
  const [logist, setLogist] = useState({
    openStatus: 0, //店铺营业状态 0不营业 1营业
    id: 0,
    isScan: 0,
    isTakeaway: 0,
    productType: 0,
    isAllday: 0,
  });
  const [printerInfo, serPrinterInfo] = useState();
  const [ossData, setOssData] = useState({});

  const [form] = Form.useForm();
  const [custviceForm] = Form.useForm();
  const [bannerForm] = Form.useForm();
  const [couponForm] = Form.useForm();
  const [ewmForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [terminalForm] = Form.useForm();
  const [terminalLoading, setTerminalLoading] = useState(false);
  const [terminalLoading2, setTerminalLoading2] = useState(false);

  useEffect(() => {
    request();
  }, []);
  const request = async () => {
    try {
      const { data } = await client.query({
        query: DELIVERY_WAY,
        fetchPolicy: "no-cache",
      });

      const user = data.user;

      if (user) {
        const d = user.store;
        setLogist({
          openStatus: d.open_status,
          id: d.id,
          isScan: d.is_scan,
          isTakeaway: d.is_takeaway,
          productType: d.product_type,
          isAllday: d.is_allday,
        });
        setBusiness(d.open_status);
        setBusinessHours(d.is_allday);
        setSelDate([
          moment(d.trade_start_time * 1000),
          moment(d.trade_end_time * 1000),
        ]);
        form.setFieldsValue({
          ...d.store_info,
        });
        serPrinterInfo(d.store_info);

        setStorestate({
          QR: Boolean(d.is_scan),
          delivery: Boolean(d.is_takeaway),
        });
        if (data.custvices) {
          custviceForm.setFieldsValue({
            custvice: data.custvices,
          });
        }
        couponForm.setFieldsValue({
          rebate_rate: d.rebate_rate
        })
        ewmForm.setFieldsValue({
          wecom_intro: d.wecom_intro || '长按添加店长微信领取专享福利',
          wecom_qrcode: d.wecom_qrcode && d.wecom_qrcode.url ? d.wecom_qrcode.url : ''
        })
        bannerForm.setFieldsValue({
          banner: d.background_img && d.background_img.url ? d.background_img.url : ''
        })
        terminalForm.setFieldsValue({
          background_img: d?.store_client?.background_img?.url ? d.store_client.background_img.url : '',
          background_logo: d?.store_client?.background_logo?.url ? d.store_client.background_logo.url : '',
        })
      }
      if (data.oss) {
        setOssData(data.oss)
      }
    } catch (error) {}
  };

  const { setCuetone } = useMainContext();
  const [value, setValue] = useState({
    proceed: "正在营业",
    close: "关闭店铺",
  });
  const [storestate, setStorestate] = useState({
    QR: false,
    delivery: false,
  });
  const [business, setBusiness] = useState(1);
  const [local, setLocal] = useState(() => {
    const cuetone = Cookies.get("cuetone");
    return (cuetone && JSON.parse(cuetone)) || 0;
  });
  const [businessHours, setBusinessHours] = useState(0);
  const [selDate, setSelDate] = useState([]);

  const changeStatus = (n) => {
    //   1为关闭，0为开启
    Cookies.set("cuetone", n);
    setCuetone(n);
    setLocal(n);
  };
  const changeSwitch = (n, item) => {
    storestate[n] = item;
    setStorestate({ ...storestate });
  };

  const clickStoreType = async () => {
    if (storestate.QR === false && storestate.delivery === false) {
      message.warning("必须选一种店铺类型");
      return;
    }
    try {
      const { data } = await client.mutate({
        mutation: Change_Store_Mutation,
        variables: {
          is_scan: storestate.QR === true ? 1 : 0,
          is_takeaway: storestate.delivery === true ? 1 : 0,
        },
        fetchPolicy: "no-cache",
      });
      if (data) {
        Message.success("修改成功");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {}
  };
  const clickStoreStatus = async () => {
    const params = {
      open_status: business,
      ...(business === 1
        ? {
            is_allday: businessHours,
            ...(businessHours === 0
              ? {
                  trade_start_time: +moment(
                    `${moment().format("YYYY-MM-DD")} ${selDate[0].format(
                      "HH:mm:ss"
                    )}`
                  ).format("X"),
                  trade_end_time: +moment(
                    `${moment().format("YYYY-MM-DD")} ${selDate[1].format(
                      "HH:mm:ss"
                    )}`
                  ).format("X"),
                }
              : {}),
          }
        : {}),
    };
    try {
      const { data } = await client.mutate({
        mutation: Change_Store_Mutation,
        variables: params,
        fetchPolicy: "no-cache",
      });
      if (data) {
        Message.success("修改成功");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {}
  };

  const onPrinters = () => {
    try {
      if (printerInfo?.feieyun_key) {
        Modal.confirm({
          content: "是否确认解除云打印机",
          onOk() {
            client
              .mutate({
                mutation: UNBIND_PRINTER,
              })
              .then(() => {
                Message.success("设置成功");
                request();
              });
          },
        });
      } else {
        form.validateFields().then((v) => {
          client
            .mutate({
              mutation: BIND_PRINTER,
              variables: v,
            })
            .then(() => {
              Message.success("设置成功");
              request();
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
    } catch (error) {
      Message.fail("设置失败，请重试");
    }
  };

  const businessState = (index) => {
    setBusiness(index);
  };

  const changebusinessHours = (type) => {
    setBusinessHours(type);
  };
  const Hours = (value) => {
    setSelDate([value[0], value[1]]);
  };

  // 二维码 onChange
  const ewmOnChange = ({ file }) => {
    if (file.status === 'uploading') {
      setLoading(true);
      return
    }
    if (file.status === 'done') {
      const suffix = file.name.slice(file.name.lastIndexOf("."));
      const url = app.oss_host + ossData.dir + file.uid + suffix;
      setLoading(false);
      ewmForm.setFieldValue('wecom_qrcode', url)
      ewmForm.validateFields()
    }
  };
  const getExtraData = (file) => {
    const suffix = file.name.slice(file.name.lastIndexOf("."));

    file.uid = md5(
      `${file.name}${new Date().getTime()}${Cookies.get("store_id") || 0}`
    );

    console.log(file, "-----extra data---------");
    return {
      key: ossData.dir + file.uid + suffix,
      OSSAccessKeyId: ossData.accessid,
      policy: ossData.policy,
      Signature: ossData.signature,
    };
  };

  // 商家返现比例（吃饭餐劵比例）保存
  const rebateRateSave = async (v) => {
    try {
      const { data } = await client.mutate({
        mutation: REBATE_RATE_SAVE,
        variables: v,
      })
      if (data?.RebateRateSave?.status === 'success') {
        Message.success('保存成功')
        request()
      }
    } catch(e) {}
  }

  // 商家企业微信设置
  const wecomQrcodeSave = async (v) => {
    if (loading) return
    try {
      const { data } = await client.mutate({
        mutation: WECOM_QRCODE_SAVE,
        variables: {
          ...v,
          wecom_qrcode: v.wecom_qrcode.replace(`${app.oss_host}`, "")
        },
      })
      if (data?.WecomQrcodeSave?.status === 'success') {
        Message.success('保存成功')
        request()
      }
    } catch(e) {}
  }

  // banner onChange
  const bannerOnChange = ({ file }) => {
    if (file.status === 'uploading') {
      setLoading2(true);
      return
    }
    if (file.status === 'done') {
      const suffix = file.name.slice(file.name.lastIndexOf("."));
      const url = app.oss_host + ossData.dir + file.uid + suffix;
      setLoading2(false);
      bannerForm.setFieldValue('banner', url)
      bannerForm.validateFields()
    }
  }
  // 商家店铺店招设置
  const storeBackgroundSave = async (v) => {
    if (loading2) return
    try {
      const { data } = await client.mutate({
        mutation: STORE_BACKGROUND_SAVE,
        variables: {
          background_img: v.banner.replace(`${app.oss_host}`, "")
        },
      })
      if (data?.StoreBackgroundSave?.status === 'success') {
        Message.success('保存成功')
        request()
      }
    } catch(e) {}
  }

  const onTerminalBeforeUpload = (file, name) => {
    return new Promise((resolve, reject) => {
      getBase64(file).then((url) => {
        const img = new Image()
        img.onload = () => {
          const aspect = img.naturalWidth / img.naturalHeight
          if (name === 'background_img') {
            if ((img.naturalWidth === 1920 && img.naturalHeight === 1080) || aspect === 16 / 9) {
              resolve(file)
            } else {
              message.error('请上传1920*1080px或比例为16:9的图片!')
              reject()
            }
          } else if (name === 'background_logo') {
            if ((img.naturalWidth === 186 && img.naturalHeight === 56) || aspect === 10 / 3) {
              resolve(file)
            } else {
              message.error('请上传186*56px或比例为10:3的图片!')
              reject()
            }
          }
        }
        img.onerror = () => {
          reject()
        }
        img.src = url
      }).catch(reject)
    })
  }
  // 收银台终端图片change
  const onTerminalChange = ({ file }, name) => {
    if (file.status === 'uploading') {
      if (name === 'background_img') {
        setTerminalLoading(true);
      } else if (name === 'background_logo') {
        setTerminalLoading2(true);
      }
      return
    }
    if (file.status === 'done') {
      const suffix = file.name.slice(file.name.lastIndexOf("."));
      const url = app.oss_host + ossData.dir + file.uid + suffix;
      if (name === 'background_img') {
        setTerminalLoading(false);
      } else if (name === 'background_logo') {
        setTerminalLoading2(false);
      }
      terminalForm.setFieldValue(name, url)
    }
  }

  // 收银台终端设置提交
  const onTerminalSubmit = async (v) => {
    console.log(v)
    if (terminalLoading || terminalLoading2) return
    try {
      const { data } = await client.mutate({
        mutation: STORE_CLIENT_SETTING,
        variables: {
          background_img: v.background_img.replace(`${app.oss_host}`, ""),
          background_logo: v.background_logo.replace(`${app.oss_host}`, "")
        },
      })
      if (data?.StoreClientSetting?.status === 'success') {
        Message.success('保存成功')
        request()
      }
    } catch(e) {}
  }

  return (
    <div className="setting">
      <div className="logistics-wrapper">
        <div className="logistics-content">
          <div className="logistics-content_title">
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                marginTop: 1,
              }}
            >
              订单提示音
            </span>
          </div>
          <div className="logistics-content_main">
            <Radio.Group value={local}>
              <Radio
                style={{ fontSize: 16, marginRight: 30, marginBottom: 10 }}
                value={0}
                onClick={() => changeStatus(0)}
              >
                开启
                <span className="tip">
                  （同城配送订单有新的订单产生时，会播放提示音）
                </span>
              </Radio>
              <Radio
                style={{ fontSize: 16 }}
                value={1}
                onClick={() => changeStatus(1)}
              >
                关闭
                <span className="tip">（不播放提示音）</span>
              </Radio>
            </Radio.Group>
            <p className="tip">
              注意：使用电脑打开商家后台时，请确保电脑连接音响设备，并开启声音
            </p>
          </div>
        </div>
      </div>
      {logist.productType == 0 ? (
        <div className="logistics-wrapper">
          <div className="logistics-content">
            <div className="logistics-content_title">
              <span
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: 1,
                }}
              >
                店铺类型*
              </span>
            </div>

            <div className="logistics-content_main">
              实体商店
              <div>
                <div className="logistics-content_main_switch">
                  <Switch
                    checked={storestate.QR}
                    onChange={() => changeSwitch("QR", !storestate.QR)}
                    style={{ marginRight: 12 }}
                  />
                  扫码点餐（用户扫码下单点餐）
                </div>
                <Switch
                  checked={storestate.delivery}
                  onChange={() => changeSwitch("delivery", !storestate.delivery)}
                  style={{ marginRight: 12 }}
                />
                需配送发货
              </div>
              <div className="logistics-content_main_btn">
                <Button type="primary" onClick={() => clickStoreType()}>
                  确定
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="logistics-wrapper">
        <div className="logistics-content">
          <div className="logistics-content_title">
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                marginTop: 1,
              }}
            >
              店铺营业状态
            </span>
          </div>
          <div className="logistics-content_main">
            <div className="logistics-content_main_stateGroup">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                value={business}
              >
                <Radio.Button value={1} onClick={() => businessState(1)}>
                  {value.proceed}
                </Radio.Button>
                <Radio.Button value={0} onClick={() => businessState(0)}>
                  {value.close}
                </Radio.Button>
              </Radio.Group>
            </div>

            <div className="logistics-content_main_businessGroup">
              {business === 1 ? (
                <Radio.Group value={businessHours}>
                  <Radio
                    style={{ fontSize: 16, marginRight: 30, marginBottom: 10 }}
                    value={0}
                    onClick={() => changebusinessHours(0)}
                  >
                    设置营业时间
                    <span className="tip">
                      <TimePicker.RangePicker
                        disabled={businessHours !== 0}
                        onChange={Hours}
                        value={selDate}
                      />
                    </span>
                    <p>
                      （设置好每日营业时间后，店铺在非营业段时间将为打烊状态，用户无法下单）
                    </p>
                  </Radio>
                  <div>
                    <Radio
                      style={{ fontSize: 16 }}
                      value={1}
                      onClick={() => changebusinessHours(1)}
                    >
                      24小时营业
                    </Radio>
                  </div>
                </Radio.Group>
              ) : null}
            </div>
            <div className="logistics-content_main_btn">
              <Button type="primary" onClick={() => clickStoreStatus()}>
                确定
              </Button>
            </div>
          </div>
        </div>
      </div>
      {Cookies.get("store_type") != 1 && (
        <div className="logistics-wrapper">
          <div className="logistics-content">
            <div className="logistics-content_title">
              <div
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: 1,
                }}
              >
                绑定云打印机
              </div>
            </div>
          </div>
          <div className="logistics-form page-wrapper">
            <Form form={form}>
              <Form.Item
                label="sn码"
                name="feieyun_sn"
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="请输入sn码" />
              </Form.Item>
              <Form.Item
                label="key码"
                name="feieyun_key"
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="请输入sn码" />
              </Form.Item>
              <Form.Item label=" " colon={false} onClick={(e) => onPrinters(e)}>
                <Button type="primary">
                  {printerInfo?.feieyun_key ? "解除绑定" : "确认绑定"}
                </Button>
              </Form.Item>
              <Form.Item
                label=" "
                colon={false}
                extra={
                  <span>
                    (开启新订单自动打印，用户线上商城下单后自动打印关闭则需要在订单中手动打印)
                  </span>
                }
              >
                <Space
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  <Form.Item
                    noStyle
                    valuePropName="checked"
                    name="is_print_neworder"
                  >
                    <Switch
                      onChange={(v) => {
                        client.mutate({
                          mutation: CHANGE_STORE_INFO,
                          variables: {
                            is_print_neworder: Number(v),
                          },
                        });
                      }}
                    />
                  </Form.Item>
                  <span
                    style={{
                      display: "inline-block",
                      margin: "0 5px",
                    }}
                  >
                    新订单自动打印
                  </span>
                </Space>
              </Form.Item>

              {printerInfo?.feieyun_key && (
                <Form.Item label="打印份数">
                  <Space>
                    <Form.Item
                      noStyle
                      name="print_num"
                      rules={[
                        {
                          required: true,
                          message: "请输入打印份数",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        style={{
                          width: 50,
                          marginRight: 5,
                        }}
                        min={1}
                        max={3}
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      onClick={(v) => {
                        form.validateFields(["print_num"]).then((v) => {
                          client
                            .mutate({
                              mutation: CHANGE_STORE_INFO,
                              variables: {
                                print_num: +v.print_num,
                              },
                            })
                            .then(() => {
                              Message.success("设置成功");
                            })
                            .catch(() => {
                              Message.fail("设置失败，请重试");
                            });
                        });
                      }}
                    >
                      编辑
                    </Button>
                  </Space>
                </Form.Item>
              )}
            </Form>
          </div>
        </div>
      )}

      {/* 吃饭餐券比例 */}
      {/* <div className="logistics-wrapper">
        <div className="logistics-content">
          <div className="logistics-content_title">
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                lineHeight: '32px'
              }}
            >
              吃饭餐券比例
            </span>
          </div>
          <div className="logistics-content_main">
            <Form
              autoComplete="off"
              form={couponForm}
              onFinish={(value) => rebateRateSave(value)}
            >
              <Space size={6} align="start">
                <div style={{ color: '#1B1A1E', lineHeight: '32px' }}>商品价格 X</div>
                <Form.Item
                  name="rebate_rate"
                  rules={[
                    {
                      required: true,
                      message: "请输入吃饭餐券比例",
                    },
                  ]}
                  style={{ display: 'inline-block' }}
                >
                  <InputNumber min={0} placeholder="请输入比例" style={{ width: 118 }} />
                </Form.Item>
                <div style={{ color: '#1B1A1E', lineHeight: '32px' }}>% = 送吃饭餐券金额</div>
              </Space>
              <Form.Item style={{ margin: 0 }}>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div> */}

      {/* 店铺店招 */}
      <div className="logistics-wrapper">
        <div className="logistics-content">
          <div className="logistics-content_title">
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                lineHeight: '32px'
              }}
            >
              店铺店招
            </span>
          </div>
          <div className="logistics-content_main">
            <Form
              autoComplete="off"
              form={bannerForm}
              onFinish={storeBackgroundSave}
            >
              {(data) => {
                return (
                  <>
                    <Form.Item
                      name="banner"
                      rules={[
                        {
                          required: true,
                          message: "请上传店招图片",
                        },
                      ]}
                      trigger=""
                      extra={<div style={{ fontSize: 12, color: '#8B8B92', lineHeight: '24px' }}>建议上传750*308px或比例为12:5</div>}
                    >
                      <ImgCrop aspect={12 / 5} rotate={true}>
                        <Upload
                          listType="picture-card"
                          className={`ewm-uploader is-border ${data.banner ? 'ewm-uploader--active' : ''}`}
                          showUploadList={false}
                          action={`https://${ossData.host}`}
                          beforeUpload={beforeUpload}
                          data={getExtraData}
                          onChange={bannerOnChange}
                        >
                          {data.banner && !loading2 ? (
                            <img className="ewm-uploader__img" src={data.banner} style={{ objectFit: 'contain' }} />
                          ) : (
                            <>
                              {loading2
                                ? <LoadingOutlined style={{ fontSize: 36 }} />
                                : <img className="ewm-uploader__icon" src={require('@/assets/images/icon-plus.png')} />
                              }
                              <span className="ewm-uploader__text">上传图片</span>
                            </>
                          )}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                    <Form.Item style={{ margin: 0 }}>
                      <Button type="primary" htmlType="submit">
                        保存
                      </Button>
                    </Form.Item>
                  </>
                )
              }}
            </Form>
          </div>
        </div>
      </div>

      {/* 收银台终端 */}
      <div className="logistics-wrapper">
        <div className="logistics-content">
          <div className="logistics-content_title">
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                lineHeight: '32px'
              }}
            >
              收银台终端设置
            </span>
          </div>
          <div className="logistics-content_main">
            <Form
              autoComplete="off"
              form={terminalForm}
              onFinish={onTerminalSubmit}
            >
              {(data) => {
                return (
                  <>
                    <Form.Item
                      name="background_img"
                      rules={[
                        {
                          required: true,
                          message: "请上传背景",
                        },
                      ]}
                      trigger=""
                      extra={<div style={{ fontSize: 12, color: '#8B8B92', lineHeight: '24px' }}>建议上传1920*1080px或比例为16:9</div>}
                    >
                      <Upload
                        listType="picture-card"
                        className={`ewm-uploader is-border ${data.background_img ? 'ewm-uploader--active' : ''}`}
                        showUploadList={false}
                        action={`https://${ossData.host}`}
                        beforeUpload={(file) => onTerminalBeforeUpload(file, 'background_img')}
                        data={getExtraData}
                        onChange={(v) => onTerminalChange(v, 'background_img')}
                      >
                        {data.background_img && !terminalLoading ? (
                          <img className="ewm-uploader__img" src={data.background_img} style={{ objectFit: 'contain' }} />
                        ) : (
                          <>
                            {terminalLoading
                              ? <LoadingOutlined style={{ fontSize: 36 }} />
                              : <img className="ewm-uploader__icon" src={require('@/assets/images/icon-plus.png')} />
                            }
                            <span className="ewm-uploader__text">上传背景</span>
                          </>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name="background_logo"
                      rules={[
                        {
                          required: true,
                          message: "请上传logo",
                        },
                      ]}
                      trigger=""
                      extra={<div style={{ fontSize: 12, color: '#8B8B92', lineHeight: '24px' }}>建议上传186*56px或比例为10:3</div>}
                    >
                      <Upload
                        listType="picture-card"
                        className={`ewm-uploader is-border ${data.background_logo ? 'ewm-uploader--active' : ''}`}
                        showUploadList={false}
                        action={`https://${ossData.host}`}
                        beforeUpload={(file) => onTerminalBeforeUpload(file, 'background_logo')}
                        data={getExtraData}
                        onChange={(v) => onTerminalChange(v, 'background_logo')}
                      >
                        {data.background_logo && !terminalLoading2 ? (
                          <img className="ewm-uploader__img" src={data.background_logo} style={{ objectFit: 'contain' }} />
                        ) : (
                          <>
                            {terminalLoading2
                              ? <LoadingOutlined style={{ fontSize: 36 }} />
                              : <img className="ewm-uploader__icon" src={require('@/assets/images/icon-plus.png')} />
                            }
                            <span className="ewm-uploader__text">上传logo</span>
                          </>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item style={{ margin: 0 }}>
                      <Button type="primary" htmlType="submit">
                        保存
                      </Button>
                    </Form.Item>
                  </>
                )
              }}
            </Form>
          </div>
        </div>
      </div>

      {/* 添加店铺客服 */}
      <div className="logistics-wrapper">
        <div className="logistics-content">
          <div className="logistics-content_title">
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                marginTop: 1,
              }}
            >
              添加店铺客服
            </span>
          </div>

          <div className="logistics-content_main">
            <Form
              autoComplete="off"
              form={custviceForm}
              onFinish={(value) => {
                const { custvice } = value;
                Promise.all(
                  custvice.map((item) => {
                    return client.mutate({
                      mutation: CREATE_CUSTVICE,
                      variables: {
                        ...item,
                        id: item.id || null,
                      },
                    });
                  })
                ).then(() => {
                  Message.success("保存成功");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                });
              }}
            >
              {(data) => {
                return (
                  <>
                    <Form.List name="custvice">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, index) => (
                              <Space
                                key={key}
                                style={{ display: "flex", marginBottom: 8 }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  fieldKey={[fieldKey, "name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "请输入客服名称",
                                    },
                                  ]}
                                >
                                  <Input placeholder="请输入客服名称" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "id"]}
                                  fieldKey={[fieldKey, "id"]}
                                  noStyle
                                  initialValue={0}
                                >
                                  <Input type="hidden" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "mobile"]}
                                  fieldKey={[fieldKey, "mobile"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "请输入客服手机号码",
                                    },
                                    {
                                      pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                                      message: "请输入正确的手机号",
                                    },
                                  ]}
                                >
                                  <Input placeholder="请输入客服手机号码" />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    const { id } = data.custvice[index];
                                    if (id) {
                                      Modal.confirm({
                                        content: "是否删除该客服？",
                                        async onOk() {
                                          const { data } = await client.mutate({
                                            mutation: DEL_CUSTVICE,
                                            variables: { id },
                                            fetchPolicy: "no-cache",
                                          });
                                          if (data) {
                                            remove(name);
                                          }
                                        },
                                      });
                                    } else {
                                      remove(name);
                                    }
                                  }}
                                />
                              </Space>
                            )
                          )}
                          {data.custvice?.length !== 5 && (
                            <Form.Item>
                              <Button
                                type="dashed"
                                style={{
                                  width: 200,
                                }}
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                添加
                              </Button>
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Form.List>
                    <Form.Item style={{ margin: 0 }}>
                      <Button type="primary" htmlType="submit">
                        保存
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>

      {/* 上传企业微信 */}
      <div className="logistics-wrapper" style={{ margin: 0 }}>
        <div className="logistics-content">
          <div className="logistics-content_title">
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                lineHeight: '32px'
              }}
            >
              上传企业微信
            </span>
          </div>
          <div className="logistics-content_main">
            <Form
              autoComplete="off"
              form={ewmForm}
              onFinish={wecomQrcodeSave}
            >
              {(data) => {
                return (
                  <>
                    <Form.Item
                      name="wecom_qrcode"
                      rules={[
                        {
                          required: true,
                          message: "请上传企业二维码",
                        },
                      ]}
                      trigger=""
                    >
                      <Upload
                        listType="picture-card"
                        className={`ewm-uploader ${data.wecom_qrcode ? 'ewm-uploader--active' : ''}`}
                        showUploadList={false}
                        action={`https://${ossData.host}`}
                        beforeUpload={beforeUpload}
                        data={getExtraData}
                        onChange={ewmOnChange}
                      >
                        {data.wecom_qrcode && !loading ? (
                          <img className="ewm-uploader__img" src={data.wecom_qrcode} />
                        ) : (
                          <>
                            {loading
                              ? <LoadingOutlined style={{ fontSize: 36 }} />
                              : <img className="ewm-uploader__icon" src={require('@/assets/images/icon-plus.png')} />
                            }
                            <span className="ewm-uploader__text">企业微信二维码</span>
                          </>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name="wecom_intro"
                      rules={[
                        {
                          required: true,
                          message: "请输入",
                        },
                      ]}
                    >
                      <Input
                        showCount={{
                          formatter: ({value, count, maxLength}) => (
                            <div style={{ fontSize: 12 }}>{count}/{maxLength}</div>
                          )
                        }}
                        maxLength={15}
                        placeholder="请输入"
                        style={{ width: 292 }}
                      />
                    </Form.Item>
                    <Form.Item style={{ margin: 0 }}>
                      <Button type="primary" htmlType="submit">
                        保存
                      </Button>
                    </Form.Item>
                  </>
                )
              }}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Logistics;
