import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import Cookies from "js-cookie";
import { catchError } from "./catchError";

const HOST = process.env.HOST;
const PORT = process.env.PORT;
const PROXY_URL = HOST ? `https://${HOST}:${PORT}` : ''; // package.json的代理url


const dzansUrl = '/api/graphql/store' // 典栈 api

const httpLink = new HttpLink({ uri: dzansUrl });
// 中间件验证是否登录
const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: Cookies.get("token") || null
      }
    });

    return forward(operation).map(response => {
        return response
    })
});

const errorLink = onError((err) => {
    
    catchError(err)
})

const authLink = middlewareLink.concat(httpLink);

const client = new ApolloClient({
  link: errorLink.concat(authLink),
  cache: new InMemoryCache()
});

export default client