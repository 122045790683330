import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Select,
    DatePicker,
    Space,
    Table,
    Image,
    Modal,
    message,
    Spin,
} from 'antd'
import { DownloadOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import { activityDel, getActivityList, getBusinessDistrict, getRegion, getTypeList, toExportExcel } from '@/apis/activity'
import './index.less'

const HeaderCell = [
    {
        title: '序号',
        dataIndex: 'id',
    },
    {
        title: '封面',
        dataIndex: 'cover',
    },
    {
        title: '活动名称',
        dataIndex: 'title',
    },
    {
        title: '创建商家名称',
        dataIndex: 'store_name',
    },
    {
        title: '状态',
        dataIndex: 'status_html',
    },
    {
        title: '发布时间',
        dataIndex: 'publish_date',
    },
    {
        title: '活动生活圈',
        dataIndex: 'business_district_name',
    },
    {
        title: '活动参与人数',
        dataIndex: 'join_user_num',
    },
    {
        title: '参加商家数',
        dataIndex: 'join_store_num',
    },
    {
        title: '活动时间',
        dataIndex: 'activity_date_html',
    },
    {
        title: '活动营业额',
        dataIndex: 'turnover',
    },
    {
        title: '备注',
        dataIndex: 'examine_msg',
    },
    {
        title: '操作',
        dataIndex: 'optional',
        fixed: 'right',
    },
]

const statusOptions = [
    { value: 1, label: '进行中' },
    { value: 2, label: '已结束' },
    { value: 3, label: '未开始' },
    { value: 4, label: '待审批' },
    { value: 5, label: '驳回' },
]

const Activity = (props) => {
    const { history } = props
    const [form] = Form.useForm()
    const [typeOptions, setTypeOptions] = useState([])
    const [regionOptions, setRegionOptions] = useState([])
    const [cityOptions, setCityOptions] = useState([])
    const [businessOptions, setBusinessOptions] = useState([])
    const [pageInfo, setPageInfo] = useState({
        page: 1,
        limit: 10,
    })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getTypeList().then(({ data }) => {
            setTypeOptions(data.map(t => ({
                value: t.id,
                label: t.type_name
            })))
        })
        getRegionData()
    }, [])

    useEffect(() => {
        getList()
    }, [pageInfo])

    // 获取省市区
    const getRegionData = async() => {
        try {
            const { data } = await getRegion({ id: 0 })
            setRegionOptions(data.map(t => ({
                value: t.id,
                label: t.name
            })))
        } catch (e) {
            console.log(e)
        }
    }
    const onProvinceChange = async(id) => {
        form.setFieldsValue({
            city: undefined,
            business_district_id: undefined
        })
        setCityOptions([])
        setBusinessOptions([])
        try {
            const { data } = await getRegion({ id })
            setCityOptions(data.map(t => ({
                value: t.id,
                label: t.name
            })))
        } catch (e) {
            console.log(e)
        }
    }
    const onCityChange = async(id) => {
        form.setFieldsValue({
            business_district_id: undefined
        })
        setBusinessOptions([])
        try {
            const v = cityOptions.find(t => t.value === id).label
            const { data } = await getBusinessDistrict({ city: v })
            setBusinessOptions(data.map(t => ({
                value: t.id,
                label: t.name
            })))
        } catch (e) {
            console.log(e)
        }
    }


    const getList = async() => {
        try {
            const fData = form.getFieldsValue()
            const params = {
                ...pageInfo,
                status: fData.status,
                type_id: fData.type_id,
                keywords: fData.keywords,
                activity_type: fData.activity_type,
                business_district_id: fData.business_district_id,
                export_excel: 0,
            }
            if (fData.dates) {
                params.start_date = fData.dates[0].format('YYYY-MM-DD')
                params.end_date = fData.dates[1].format('YYYY-MM-DD')
            }
            const { data } = await getActivityList(params)
            setList(data.data)
            setTotal(data.total)
        } catch (e) {
            console.log(e)
        }
    }

    const toExcel = async() => {
        const fData = form.getFieldsValue()
        const params = {
            page: 1,
            limit: total,
            status: fData.status,
            type_id: fData.type_id,
            keywords: fData.keywords,
            activity_type: fData.activity_type,
            business_district_id: fData.business_district_id,
            export_excel: 1,
        }
        if (fData.dates) {
            params.start_date = fData.dates[0].format('YYYY-MM-DD')
            params.end_date = fData.dates[1].format('YYYY-MM-DD')
        }
        setLoading(true)
        await toExportExcel('/store/activity/list', params)
        setLoading(false)
    }

    const onPageChange = (page) => {
        setPageInfo({
            ...pageInfo,
            page
        })
    }

    const onReset = () => {
        form.resetFields()
        onPageChange(1)
    }

    // 新增
    const add = () => {
        history.push('/activity/add');
    }
    const edit = (id) => {
        history.push(`/activity/add?id=${id}`);
    }
    const toDetail = (id, type = 0) => {
        history.push(`/activity/detail?id=${id}&is_join=${type}`);
    }
    const del = (id) => {
        Modal.confirm({
            content: '确定删除该活动吗？',
            onOk() {
                delConfirm(id);
            },
        })
    }
    const delConfirm = async (id) => {
        try {
            await activityDel({ id })
            message.success("删除成功")
            getList()
        } catch (e) {
            console.log(e)
        }
    };

    const optionalRender = (_, record) => {
        const isShowJoin = record.status == 3 && record.is_self_publish == 0 && record.is_join == 1 && record.store_is_join == 0
        return (
            <div className="optional">
                {[3,5].includes(record.status) && record.is_self_publish === 1 && <span className="btn" onClick={() => edit(record.id)}>
                    编辑
                </span>}
                {[3,4,5].includes(record.status) && record.is_self_publish === 1 && <span className="btn" onClick={() => del(record.id)}>
                    删除
                </span>}
                <span className="btn" onClick={() => toDetail(record.id)}>
                    查看
                </span>
                {isShowJoin && <span className="btn" onClick={() => toDetail(record.id, 1)}>
                    加入活动
                </span>}
            </div>
        )
    }

    const columns1Getter = HeaderCell.map(col => {
        if (col.dataIndex === 'cover') {
            col.render = (text) => <Image width={60} height={60} src={text} />
        } else if (col.dataIndex === 'store_name' || col.dataIndex === 'examine_msg') {
            col.render = (text) => text || '-'
        } else if (col.dataIndex === 'optional') {
            col.render = optionalRender
        }
        col.align = 'center'
        return col
    })

    const TableProps = {
        columns: columns1Getter,
        dataSource: list,
        bordered: true,
        pagination: {
            current: pageInfo.page,
            position: ["bottomCenter"],
            defaultPageSize: pageInfo.limit,
            onChange: onPageChange,
            showSizeChanger: false,
            showQuickJumper: true,
            total,
            showTotal: (total) => `共${total}条`,
        },
        scroll: {
            x: "max-content",
            scrollToFirstRowOnChange: true,
        },
        rowKey: "id",
    }

    return (
        <div className="activity-wrapper">
            <Form form={form} layout="inline" colon={false} onFinish={() => onPageChange(1)} autoComplete="off">
                <Form.Item label="状态" name="status">
                    <Select
                        style={{ width: 180 }}
                        placeholder="请选择"
                        options={statusOptions}
                    />
                </Form.Item>
                <Form.Item label="类型" name="type_id">
                    <Select
                        style={{ width: 200 }}
                        placeholder="请选择数字券类型"
                        options={typeOptions}
                    />
                </Form.Item>
                <Form.Item label="创建时间" name="dates">
                    <DatePicker.RangePicker />
                </Form.Item>
                <Form.Item label="可领区域">
                    <Space>
                        <Form.Item name="province" noStyle>
                            <Select
                                style={{ width: 108 }}
                                placeholder="选择省"
                                options={regionOptions}
                                onChange={onProvinceChange}
                            />
                        </Form.Item>
                        <Form.Item name="city" noStyle>
                            <Select
                                style={{ width: 108 }}
                                placeholder="选择市"
                                options={cityOptions}
                                onChange={onCityChange}
                            />
                        </Form.Item>
                        <Form.Item name="business_district_id" noStyle>
                            <Select
                                style={{ width: 108 }}
                                placeholder="选择生活圈"
                                options={businessOptions}
                            />
                        </Form.Item>
                    </Space>
                </Form.Item>
                <Form.Item name="activity_type" initialValue={0}>
                    <Select
                        style={{ width: 200 }}
                        placeholder="请选择"
                        options={[
                            { value: 0, label: '所有活动' },
                            { value: 1, label: '我的活动' }
                        ]}
                    />
                </Form.Item>
                <Form.Item name="keywords">
                    <Input placeholder="请输入搜索内容" style={{ width: 240 }} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ marginRight: 14 }}>查询</Button>
                    <Button icon={<ReloadOutlined />} onClick={onReset}>重置</Button>
                </Form.Item>
            </Form>
            <Spin spinning={loading}>
                <div style={{ marginBottom: 16 }}>
                    <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: 14 }} onClick={add}>新增</Button>
                    <Button icon={<DownloadOutlined />} onClick={toExcel}>导出</Button>
                </div>
                <Table {...TableProps} />
            </Spin>
        </div>
    )
}

export default Activity