import axios from 'axios'
import http from '../request'
import Cookies from 'js-cookie'
import { message } from 'antd'

// 活动类型
export const getTypeList = () => http.get('/activity/activity-type-list')

// 数字券列表
export const getCouponList = (params) => http.get('/activity/numeral-coupon-list', params)

// 生活圈列表
export const getBusinessList = (params) => http.get('/activity/business-district-list', params)

// 商品列表
export const getProductList = (params) => http.get('/activity/product-list', params)

// 金沙生活圈-活动列表
export const getActivityList = (params) => http.get('/activity/list', params)

// 金沙生活圈-活动列表-查看详情
export const getActivityDetail = (params) => http.get('/activity/detail', params)

// 添加、修改活动
export const activityAdd = (params) => http.post('/activity/activity-add', params)

// 金沙生活圈-活动列表-删除
export const activityDel = (params) => http.post('/activity/del', params)

// 省市区查询
export const getRegion = (params) => http.get('/activity/region', params)

// 根据城市名称获取生活圈列表
export const getBusinessDistrict = (params) => http.get('/business-district/get-business-district-by-city', params)

// 其他商家加入活动
export const getStoreJoin = (params) => http.post('/activity/store-join', params)

// 导出Excel
export const toExportExcel = async(url, params) => {
    try {
        const res = await axios.get(url, {
            params,
            headers: {
                Authorization: Cookies.get('token')
            },
            responseType: 'blob'
        })
        if (res.data.type === 'application/json') {
            const reader = new FileReader()
            reader.readAsText(res.data, 'utf-8')
            reader.onload = function() {
                const readerres = reader.result
                const { errors } = JSON.parse(readerres)
                const { message: msg } = Array.isArray(errors) ? errors[0] : errors
                message.error(msg)
            }
        } else {
            const blob = new Blob([res.data], { type: res.headers['content-type'] })
            const objUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = objUrl
            link.download = `${Date.now()}.xlsx`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(objUrl)
        }
    } catch (e) {
        console.log(e)
    }
}