import React, { useEffect, useState, useRef } from "react";
import {
  Checkbox,
  DatePicker,
  Input,
  Message,
  Modal,
  Select,
  Steps,
  Radio,
} from "antd";
import FirstStep from "@/components/store/firstStep";
import Address from "@/components/store/address";
import BusinessImg from "@/components/FileUpload";
import Contact from "@/components/public/contact/contact";
import Centers from "@/components/public/contact/center";
import Client from "@/components/public/contact/client";
import Information from "@/components/public/contact/information";
import { handleImg, isCardNo } from "@/utils/index";

import {
  isPhone,
  isEmail,
  isInputEmailText,
  isInputNumText,
  isInputTelephoneText,
  isSocialCreditCode,
} from "@/utils/regexp";
import {
  ADD_STORE_BASE,
  ADDITIONAL,
  ADDITIONALINFO,
  AGENT,
  IMAGE2TEXT,
  STORE_AUDIT_INFO,
  UNIONPAY_BANK,
  UNIONPAY_BANK_CATEGORIES,
  UNIONPAY_REGIONS,
  CODE,
} from "@/graphql/store";
import client from "@/graphql/apollo-config";
import moment from "moment";
import { isEmpty } from "@/utils";

import "./index.less";

const axios = require("axios").default;
const { RangePicker } = DatePicker;

const { Option } = Select;
const { Step } = Steps;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const BasicInfo = (props) => {
  const [storeIndustry, setStoreIndustry] = useState([]); // 行业归属
  const [storeCategroy, setStoreCategroy] = useState([]); // 商家类目
  const [OSSData, setOSSData] = useState({});
  const [first, setFirst] = useState(true); // 是否首次
  const [isFirstBase, setFirstBase] = useState(true); // 旧商家是否首次
  const [step, setStep] = useState(0); // 步骤
  const [showMoreData, setMoreData] = useState(false); // 补充资料弹窗
  const [verifyAmount, setVerifyAmount] = useState(""); // 验证金额
  const [authImage, setAuthImage] = useState(null); // 验证图片
  const [longCredit, setLongCredit] = useState(false); // 营业执照长期
  const [longIdCard, setIdCard] = useState(false); // 身份证长期
  const [longIdCard2, setIdCard2] = useState(false); // 受益人身份证长期
  const [provinceArr, setProvinceArr] = useState([]); // 省列表
  const [cityArr, setCityArr] = useState([]); // 市列表
  const [areaArr, setAreaArr] = useState([]); // 区列表
  const [bankArr, setBankArr] = useState([]); // 支行列表
  const [bankName, setBankName] = useState([]); // 银行类目
  const [mccCode, setMccCode] = useState([]); // 经营类别类目
  const [industry, setIndustry] = useState([]); // 行业类目
  const [cityCode, setCityCode] = useState(""); // 市code
  const [categroyCode, setCategroyCode] = useState(""); // 银行类目code
  const [same, setSame] = useState(false); // 联系人与法人信息一致
  const [interval, setInterval] = useState(null);
  const [isPlatformAudit, setIsPlatformAudit] = useState(false); //入驻信息是否通过
  const LOCAL_KEY = "store";

  const [storeInfo, setStoreInfo] = useState({
    settle_status: null, // 入驻方式
    short_name: null, // 店铺简称
    store_category_id: null, // 商家类目
    store_category_name: null,
    province: "",
    city: "",
    area: "",
    lng: null,
    lat: null,
    code: null, // 推荐代理
    company_name: null, // 公司名称
    address: "", // 店铺地址
    hot_line: null, // 客服手机
    credit_code: null, // 营业执照号
    mcc_code: "", // 经营类别
    mcc_name: "",
    industry_code: "", // 行业类目
    industry_name: "",
    business_license_valid_start: null, // 营业执照开始时间
    business_license_valid_end: null, // 营业执照结束时间
    corporation: null, // 法人姓名
    id_card_no: null, // 法人身份证号
    id_card_address: null, //法人身份证地址
    id_card_valid_start: null, //身份证开始时间
    id_card_valid_end: null, //身份证结束时间
    mobile: null, // 法人手机号码
    contact_name: null, // 受益人姓名
    contact_id_card: null, // 受益人身份证
    contact_mobile: null, // 受益人电话
    contact_email: null, // 受益人邮箱
    contact_card_front: [], // 受益人身份证正面
    contact_card_back: [], // 受益人身份证背面
    contact_card_address: null, // 受益人身份证地址
    contact_card_valid_start: null, // 受益人身份证有效期开始
    contact_card_valid_end: null,  // 受益人身份证有效期结束
    bank_account_name: null, // 开户名称
    bank_account_type: null, //账户类型
    bank_account_id_card_no: null, // 开户人证件号码
    bank_account: null, // 银行卡号
    bank_name: null, // 银行名称
    bank_branch: null, // 支行名称
    bank_area: "", // 支行区
    bank_province: "", // 支行省
    bank_city: "", // 支行市
    bank_code: "", // 支行code
    business_license: [], // 营业执照
    house_num: [], // 门头照
    checkout_counter: [], // 收银台
    store_inner: [], // 店内照
    id_card_front: [], // 身份证正面
    id_card_back: [], // 身份证背面
    id_card_hand: [], // 手持身份证
    bank_id_card_front: [], // 开户人身份证正面照
    bank_id_card_back: [], // 开户人身份证背面照
    bank_card_image: [], // 结算银行卡照
    bank_hand_image: [], // 手持银行卡照
    opening_permit_image: [], // 开户许可证
    is_ushoufu: 0, //是否开通优收付
    ali_account: "", //支付宝账号
    cover: [], // 店铺头像
    images: [], // 店铺轮播图`
    video: [],
    notice: "",
    average_spend: "",
    lowest_delivery_price: "",
    email: '', // 邮箱
    bank_mobile: '', // 银行卡预留手机号
    registered_capital: '', // 注册资本
    bf_industry_code: '', // 行业归属
  });
  const [storeBase, setStoreBase] = useState({
    reject_reason: null,
    status: null,
    reject_type: null,
  });
  const [infoStatus, setInfoStatus] = useState({
    status: null,
    reject_reason: null,
    auth_image: null,
  });

  useEffect(() => {
    getStoreInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 请求支行列表
  useEffect(() => {
    if (cityCode && categroyCode) {
      queryBank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityCode, categroyCode]);

  useInterval(() => {
    localStorage.setItem(LOCAL_KEY, JSON.stringify(storeInfo));
  }, interval);

  const history = props.history;

  const rule1 = [
    {
      rule: !storeInfo.short_name || !storeInfo.short_name.trim(),
      title: "请填写店铺简称",
    },
    {
      rule: !storeInfo.store_category_id,
      title: "请选择店铺所属类目",
    },
    {
      rule: !storeInfo.average_spend,
      title: "请填写人均消费",
    },
    {
      rule: (storeInfo.average_spend + "").indexOf(".") != -1,
      title: "人均消费不能为小数",
    },
    {
      rule: !storeInfo.mcc_code,
      title: "请选择经营类别",
    },
    {
      rule: !storeInfo.bf_industry_code,
      title: "请选择所属行业",
    },
    {
      rule: !storeInfo.industry_code,
      title: "请选择行业类目",
    },
    {
      rule: !storeInfo.province,
      title: "请选择省份",
    },
    {
      rule: !storeInfo.city,
      title: "请选择市",
    },
    {
      rule: !storeInfo.area,
      title: "请选择区/县",
    },
    {
      rule: !storeInfo.address,
      title: "请填写详细地址",
    },
    {
      rule: storeInfo.address && storeInfo.address.length < 5,
      title: "请填写5个字以上的详细地址",
    },
    {
      rule: !storeInfo.hot_line,
      title: "请填写客服手机",
    },
    {
      rule:
        storeInfo.hot_line &&
        (storeInfo.hot_line.length != 11 || storeInfo.hot_line[0] != 1),
      title: "客服手机号码格式不正确",
    },
    {
      rule: !storeInfo.ali_account,
      title: "请填写支付宝账号",
    },
    {
      rule:
        storeInfo.code &&
        (storeInfo.code.length != 11 || storeInfo.code[0] != 1),
      title: "代理手机号码格式不正确",
    },
    {
      rule:
        storeInfo.settle_status !== 1 &&
        JSON.stringify(storeInfo.business_license) == "[]",
      title: "请上传营业执照",
    },
    {
      rule: storeInfo.settle_status !== 1 && !storeInfo.company_name,
      title: "请填写营业执照一致的名称",
    },
    {
      rule: storeInfo.settle_status !== 1 && !storeInfo.credit_code,
      title: "请填写统一社会信用代码",
    },
    {
      rule:
        storeInfo.settle_status !== 1 &&
        storeInfo.credit_code &&
        storeInfo.credit_code.length < 18,
      title: "统一社会信用代码必须18位",
    },
    {
      rule:
        storeInfo.settle_status !== 1 &&
        storeInfo.credit_code &&
        !isSocialCreditCode(storeInfo.credit_code),
      title: "统一社会信用代码不正确",
    },
    {
      rule:
        !longCredit &&
        storeInfo.settle_status !== 1 &&
        !storeInfo.business_license_valid_start,
      title: "请选择营业执照开始时间",
    },
    {
      rule:
        !longCredit &&
        storeInfo.settle_status !== 1 &&
        !storeInfo.business_license_valid_end,
      title: "请选择营业执照结束时间",
    },
    {
      rule: storeInfo.settle_status !== 1 && !storeInfo.registered_capital,
      title: "请填写注册资本"
    },
    {
      rule: storeInfo.house_num && JSON.stringify(storeInfo.house_num) == "[]",
      title: "请上传门头照",
    },
    {
      rule: storeInfo.cover && JSON.stringify(storeInfo.cover) == "[]",
      title: "请上传店铺头像",
    },
    {
      rule:
        storeInfo.settle_status !== 1 &&
        JSON.stringify(storeInfo.checkout_counter) == "[]",
      title: "请上传收银台",
    },
    {
      rule: JSON.stringify(storeInfo.store_inner) == "[]",
      title: "请上传店内照",
    },
  ];

  const rule2 = [
    {
      rule:
        storeInfo.id_card_front &&
        JSON.stringify(storeInfo.id_card_front) == "[]",
      title: "请上传身份证正面照",
    },
    {
      rule:
        storeInfo.id_card_back &&
        JSON.stringify(storeInfo.id_card_back) == "[]",
      title: "请上传身份证背面照",
    },
    // {
    //   rule:
    //     storeInfo.settle_status == 0 &&
    //     JSON.stringify(storeInfo.id_card_hand) == "[]",
    //   title: "请上传手持身份证照",
    // },
    {
      rule: !storeInfo.corporation || !storeInfo.corporation.trim(),
      title: "请填写法人姓名",
    },
    {
      rule: !storeInfo.id_card_no,
      title: "请填写法人身份证号",
    },
    {
      rule: !storeInfo.id_card_address,
      title: "请填写法人身份证地址",
    },

    {
      rule: !isCardNo(storeInfo.id_card_no),
      title: "请填写正确的法人身份证号",
    },
    {
      rule: !longIdCard && !storeInfo.id_card_valid_start,
      title: "请选择身份证有效期开始时间",
    },
    {
      rule: !storeInfo.id_card_valid_end,
      title: "请选择身份证有效期结束时间",
    },
    {
      rule: !storeInfo.mobile,
      title: "请填写法人手机号码",
    },
    {
      rule: storeInfo.mobile && !isPhone(storeInfo.mobile),
      title: "法人手机号码格式不正确",
    },
    {
      rule: storeInfo.settle_status !== 1 && !storeInfo.email,
      title: "请填写邮箱"
    },
    {
      rule: storeInfo.settle_status !== 1 && !isEmail(storeInfo.email),
      title: "邮箱格式不正确"
    },
    {
      rule:
        storeInfo.settle_status == 2 &&
        storeInfo.contact_card_front &&
        JSON.stringify(storeInfo.contact_card_front) == "[]",
      title: "请上传受益人身份证正面照",
    },
    {
      rule:
        storeInfo.settle_status == 2 &&
        storeInfo.contact_card_back &&
        JSON.stringify(storeInfo.contact_card_back) == "[]",
      title: "请上传受益人身份证背面照",
    },
    {
      rule: storeInfo.settle_status == 2 && (!storeInfo.contact_name || !storeInfo.contact_name.trim()),
      title: "请填写受益人姓名",
    },
    {
      rule: storeInfo.settle_status == 2 && !storeInfo.contact_id_card,
      title: "请填写受益人身份证号",
    },
    {
      rule: storeInfo.settle_status == 2 && !isCardNo(storeInfo.contact_id_card),
      title: "请填写正确的受益人身份证号",
    },
    {
      rule: storeInfo.settle_status == 2 && !storeInfo.contact_card_address,
      title: "请填写受益人身份证地址",
    },
    {
      rule: storeInfo.settle_status == 2 && !longIdCard2 && !storeInfo.contact_card_valid_start,
      title: "请选择受益人身份证有效期开始时间",
    },
    {
      rule: storeInfo.settle_status == 2 && !longIdCard2 && !storeInfo.contact_card_valid_end,
      title: "请选择受益人身份证有效期结束时间",
    },
    {
      rule: storeInfo.settle_status == 2 && !storeInfo.contact_mobile,
      title: "请填写受益人手机号码或电话号码",
    },
    {
      rule: storeInfo.settle_status == 2 && !storeInfo.contact_email,
      title: "请填写受益人邮箱",
    },
    {
      rule: storeInfo.settle_status == 2 && storeInfo.contact_email && !isEmail(storeInfo.contact_email),
      title: "受益人邮箱格式不正确",
    },
  ];

  const rule3 = [
    {
      rule:
        storeInfo.bank_id_card_front &&
        JSON.stringify(storeInfo.bank_id_card_front) == "[]",
      title: "请上传开户人身份证正面照",
    },
    {
      rule:
        storeInfo.bank_id_card_back &&
        JSON.stringify(storeInfo.bank_id_card_back) == "[]",
      title: "请上传开户人身份证背面照",
    },
    {
      rule:
        storeInfo.settle_status != 2 &&
        JSON.stringify(storeInfo.bank_card_image) == "[]",
      title: "请上传结算银行卡照",
    },
    // {
    //   rule:
    //     storeInfo.settle_status != 2 &&
    //     JSON.stringify(storeInfo.bank_hand_image) == "[]",
    //   title: "请上传手持银行卡照",
    // },
    {
      rule:
        storeInfo.bank_account_type == 0 &&
        JSON.stringify(storeInfo.opening_permit_image) == "[]",
      title: "请上传开户许可证",
    },
    {
      rule: !storeInfo.bank_account,
      title: "请填写银行卡号",
    },
    {
      rule:
        storeInfo.bank_account &&
        (storeInfo.bank_account.length < 9 ||
          storeInfo.bank_account.length > 30),
      title: "银行卡号限制9-30位",
    },
    {
      rule: !storeInfo.bank_name,
      title: "请选择银行名称",
    },
    {
      rule: !storeInfo.bank_province,
      title: "请选择支行所在省份",
    },
    {
      rule: !storeInfo.bank_city,
      title: "请选择支行所在市",
    },
    {
      rule: !storeInfo.bank_branch,
      title: "请选择支行名称",
    },
    {
      rule: !storeInfo.bank_account_name || !storeInfo.bank_account_name.trim(),
      title: "请填写开户名称",
    },
    {
      rule: !storeInfo.bank_account_id_card_no,
      title: "请填写开户人证件号码",
    },
    {
      rule: !isCardNo(storeInfo.bank_account_id_card_no),
      title: "请填写正确的开户人证件号码",
    },
    {
      rule: storeInfo.settle_status == 1 && !storeInfo.bank_mobile,
      title: "请填写银行卡预留手机号"
    },
    {
      rule: storeInfo.settle_status == 1 && !isPhone(storeInfo.bank_mobile),
      title: "预留手机号格式不正确"
    }
  ];

  const bankType = [
    {
      name: "对公",
      id: 0,
    },
    {
      name: "对私",
      id: 1,
    },
  ];

  const queryBank = async () => {
    try {
      const { data } = await client.query({
        query: UNIONPAY_BANK,
        variables: {
          city_code: cityCode,
          category_code: categroyCode,
        },
        fetchPolicy: "no-cache",
      });
      if (data) {
        setBankArr(data.unionpayBank);
      }
    } catch (error) {}
  };

  // 页面一进来
  const getStoreInfo = async () => {
    try {
      const { data } = await client.query({
        query: STORE_AUDIT_INFO,
        fetchPolicy: "no-cache",
      });
      console.log("提交的data", data);
      if (data) {
        setOSSData({
          ...data.oss,
        });
        setStoreCategroy([...data.storeCategory]);
        setStoreIndustry(data.storeIndustry)
        if (data.storeInfo) {
          const user = data.user;
          const storeData = data.storeInfo;
          if (storeData && storeData.store_base_platform_audit) {
            setIsPlatformAudit(storeData.store_base_platform_audit.status == 1);
          }
          let a = null;
          let b = null;
          // 没有审核信息拿cookie里面,cookie返回的是数组，后台返回的是对象，所以要分开处理
          if (!storeData.store_base_audit) {
            // const sbpa = storeData.store_base_platform_audit;
            const localStore = localStorage.getItem(LOCAL_KEY);

            a = localStore ? JSON.parse(localStore) : storeInfo;

            b = {
              code: a.code == "" ? user.parent_mobile : a.code,
              business_license: imgData(
                a.business_license && a.business_license.length > 0
                  ? a.business_license[0].url
                  : "",
                "business_license"
              ),
              house_num: imgData(
                storeData.house_num && storeData.house_num.url
                  ? storeData.house_num.url
                  : "",
                "house_num"
              ),
              checkout_counter: imgData(
                a.checkout_counter && a.checkout_counter.length > 0
                  ? a.checkout_counter[0].url
                  : "",
                "checkout_counter"
              ),
              store_inner: imgData(
                storeData.store_inner && storeData.store_inner.url
                  ? storeData.store_inner.url
                  : "",
                "store_inner"
              ),
              id_card_front: imgData(
                a.id_card_front && a.id_card_front.length > 0
                  ? a.id_card_front[0].url
                  : "",
                "id_card_front"
              ),
              id_card_back: imgData(
                a.id_card_back && a.id_card_back.length > 0
                  ? a.id_card_back[0].url
                  : "",
                "id_card_back"
              ),
              id_card_hand: imgData(
                a.id_card_hand && a.id_card_hand.length > 0
                  ? a.id_card_hand[0].url
                  : "",
                "id_card_hand"
              ),
              bank_id_card_front: imgData(
                a.bank_id_card_front && a.bank_id_card_front.length > 0
                  ? a.bank_id_card_front[0].url
                  : "",
                "bank_id_card_front"
              ),
              bank_id_card_back: imgData(
                a.bank_id_card_back && a.bank_id_card_back.length > 0
                  ? a.bank_id_card_back[0].url
                  : "",
                "bank_id_card_back"
              ),
              bank_hand_image: imgData(
                a.bank_hand_image && a.bank_hand_image.length > 0
                  ? a.bank_hand_image[0].url
                  : "",
                "bank_hand_image"
              ),
              opening_permit_image: imgData(
                a.opening_permit_image && a.opening_permit_image.length > 0
                  ? a.opening_permit_image[0].url
                  : "",
                "opening_permit_image"
              ),
              bank_card_image: imgData(
                a.bank_card_image && a.bank_card_image.length > 0
                  ? a.bank_card_image[0].url
                  : "",
                "bank_card_image"
              ),
              cover: imgData(a.cover && a.cover.url, "cover"),
              video: imgData(a.video && a.video.url, "video"),
              images: a.images || [],
              contact_card_front: imgData(
                a.contact_card_front && a.contact_card_front.length > 0
                  ? a.contact_card_front[0].url
                  : "",
                "contact_card_front"
              ),
              contact_card_back: imgData(
                a.contact_card_back && a.contact_card_back.length > 0
                  ? a.contact_card_back[0].url
                  : "",
                "contact_card_back"
              ),
            };
            setInterval(5000);
          } else {
            // 首次就拿审核信息里面的，不是首次就拿门店信息
            // a = storeData.store_base_audit.is_first_audit
            //   ? storeData.store_base_audit
            //   : storeData;
            a = storeData.store_base_audit.is_first_audit
              ? storeData.store_base_audit
              : storeData.store_base_audit.status == 0
              ? storeData.store_base_audit
              : storeData;
            b = {
              code: a.code == "" ? user.parent_mobile : a.code,
              business_license: imgData(
                a.business_license && a.business_license.url,
                "business_license"
              ),
              house_num: imgData(a.house_num && a.house_num.url, "house_num"),
              checkout_counter: imgData(
                a.checkout_counter && a.checkout_counter.url,
                "checkout_counter"
              ),
              store_inner: imgData(
                a.store_inner && a.store_inner.url,
                "store_inner"
              ),
              id_card_front: imgData(
                a.id_card_front && a.id_card_front.url,
                "id_card_front"
              ),
              id_card_back: imgData(
                a.id_card_back && a.id_card_back.url,
                "id_card_back"
              ),
              id_card_hand: imgData(
                a.id_card_hand && a.id_card_hand.url,
                "id_card_hand"
              ),
              bank_id_card_front: imgData(
                a.bank_id_card_front && a.bank_id_card_front.url,
                "bank_id_card_front"
              ),
              bank_id_card_back: imgData(
                a.bank_id_card_back && a.bank_id_card_back.url,
                "bank_id_card_back"
              ),
              bank_hand_image: imgData(
                a.bank_hand_image && a.bank_hand_image.url,
                "bank_hand_image"
              ),
              opening_permit_image: imgData(
                a.opening_permit_image && a.opening_permit_image.url,
                "opening_permit_image"
              ),
              bank_card_image: imgData(
                a.bank_card_image && a.bank_card_image.url,
                "bank_card_image"
              ),
              cover: imgData(a.cover && a.cover.url, "cover"),
              video: imgData(a.video && a.video.url, "video"),
              images: a.images || [],
              contact_card_front: imgData(
                a.contact_card_front && a.contact_card_front.url,
                "contact_card_front"
              ),
              contact_card_back: imgData(
                a.contact_card_back && a.contact_card_back.url,
                "contact_card_back"
              ),
              contact_card_valid_start: a.contact_card_valid_start != '0000-00-00' ? a.contact_card_valid_start : null,
              contact_card_valid_end: a.contact_card_valid_end != '0000-00-00' ? a.contact_card_valid_end : null,

              email: a.email || '',
              bank_mobile: a.bank_mobile || '',
              registered_capital: a.registered_capital || '',
            };
          }
          if (
            (a.business_license_valid_end &&
              a.business_license_valid_end.indexOf("2099") != -1) ||
            a.settle_status == 0
          ) {
            setLongCredit(true);
          }
          if (
            a.id_card_valid_end &&
            a.id_card_valid_end.indexOf("2099") != -1
          ) {
            setIdCard(true);
          }
          if (a.contact_card_valid_end && a.contact_card_valid_end.indexOf("2099") != -1) {
            setIdCard2(true);
          }
          console.log("aaa", a);
          console.log("bbbb", b);
          setStoreInfo({
            ...a,
            ...b,
          });
          // 小于5代表没到补充资料那步，状态等于1是审核通过了，id存在是代表店铺成功开启
          // const isFirst = [0, 1, 2].includes(a.store_base_audit.status);
          const isFirst =
            (storeData.store_base_audit.status < 5 &&
              storeData.store_base_audit.status != 1) ||
            storeData.short_name == "";
          setFirst(isFirst);

          const isFirstBase =
            !storeData.store_base_audit ||
            (storeData.store_base_audit.status < 5 &&
              storeData.store_base_audit.status != 1 &&
              !storeData.is_ushoufu);
          setFirstBase(isFirstBase);
          setStoreBase({
            reject_reason:
              data.storeInfo.store_base_audit &&
              data.storeInfo.store_base_audit.reject_reason,
            status:
              data.storeInfo.store_base_audit &&
              data.storeInfo.store_base_audit.status,
            reject_type:
              data.storeInfo.store_base_audit &&
              data.storeInfo.store_base_audit.reject_type,
          });
          setInfoStatus({
            status: data.additionalInfo.status,
            reject_reason: data.additionalInfo.reject_reason,
            auth_image:
              data.additionalInfo.auth_image &&
              data.additionalInfo.auth_image.url,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const imgData = (url, name) => {
    if (url) {
      let c = {};
      c.url = url;
      c.uid = name;
      c.name = name;
      return [c];
    } else {
      return [];
    }
  };

  const setStoreInfos = (name, value) => {
    setStoreInfo({
      ...storeInfo,
      [name]: value,
    });
  };

  // 修改数据
  const changeData = (name, value) => {
    switch (name) {
      case "hot_line":
        if (isInputTelephoneText(value)) {
          setStoreInfos(name, value);
        }
        break;
      case "contact_mobile":
        if (isInputNumText(value)) {
          setStoreInfos(name, value);
        }
        break;
      case "contact_email":
        if (isInputEmailText(value)) {
          setStoreInfos(name, value);
        }
        break;
      case "bank_account":
        if (isInputNumText(value)) {
          setStoreInfos(name, value);
        }
        break;
      default:
        setStoreInfos(name, value);
        break;
    }
  };

  // 选择类型
  const chooseSettle = (i) => {
    // setLongCredit(i == 0 ? true : false);
    setLongCredit(true);
    setStoreInfo({
      ...storeInfo,
      settle_status: i,
      mcc_code: "",
      mcc_name: "",
      industry_code: "",
      industry_name: "",
      bank_account_type: i == 2 ? 0 : null,
      business_license_valid_start: i == 0 || i == 2 ? "2099-12-30" : "",
      business_license_valid_end: i == 0 || i == 2 ? "2099-12-30" : "",
    });
  };

  // 提交修改
  const submitMutate = async (lng, lat) => {
    // console.log('提交的storeinfo',storeInfo);
    try {
      const { data } = await client.mutate({
        mutation: ADD_STORE_BASE,
        variables: {
          short_name: storeInfo.short_name,
          province: storeInfo.province,
          city: storeInfo.city,
          area: storeInfo.area,
          address: storeInfo.address,
          lng: lng,
          lat: lat,
          code: storeInfo.code,
          //新增字段
          product_type: storeInfo.product_type,
          average_spend: storeInfo.average_spend,
          lowest_delivery_price: storeInfo.lowest_delivery_price || 0,
          cover: handleImg(storeInfo.cover),
          images: handleImg(storeInfo.images) || [],
          video: handleImg(storeInfo.video),
          notice: storeInfo.notice ? storeInfo.notice : "",
          mcc_code: storeInfo.mcc_code,
          industry_code: storeInfo.industry_code,
          company_name: storeInfo.company_name,
          store_category_id: storeInfo.store_category_id,
          settle_status: storeInfo.settle_status,
          hot_line: storeInfo.hot_line,
          credit_code: storeInfo.credit_code,
          business_license_valid_start: storeInfo.business_license_valid_start,
          business_license_valid_end: storeInfo.business_license_valid_end,
          corporation: storeInfo.corporation,
          id_card_no: storeInfo.id_card_no,
          id_card_address: storeInfo.id_card_address,
          id_card_valid_start: storeInfo.id_card_valid_start,
          id_card_valid_end: storeInfo.id_card_valid_end,
          mobile: storeInfo.mobile,
          business_license: handleImg(storeInfo.business_license),
          house_num: handleImg(storeInfo.house_num),
          checkout_counter: handleImg(storeInfo.checkout_counter),
          store_inner: handleImg(storeInfo.store_inner),
          id_card_front: handleImg(storeInfo.id_card_front),
          id_card_back: handleImg(storeInfo.id_card_back),
          id_card_hand: handleImg(storeInfo.id_card_hand),
          contact_name: storeInfo.contact_name,
          contact_id_card: storeInfo.contact_id_card,
          contact_mobile: storeInfo.contact_mobile,
          contact_email: storeInfo.contact_email,
          contact_card_front: handleImg(storeInfo.contact_card_front),
          contact_card_back: handleImg(storeInfo.contact_card_back),
          contact_card_address: storeInfo.contact_card_address || '',
          contact_card_valid_start: storeInfo.contact_card_valid_start || '',
          contact_card_valid_end: storeInfo.contact_card_valid_end || '',
          bank_account_name: storeInfo.bank_account_name,
          bank_account_type: storeInfo.settle_status == 2 ? 0 : 1,
          bank_account_id_card_no: storeInfo.bank_account_id_card_no,
          bank_account: storeInfo.bank_account,
          bank_name: storeInfo.bank_name,
          bank_code: storeInfo.bank_code,
          bank_branch: storeInfo.bank_branch,
          bank_province: storeInfo.bank_province,
          bank_area: storeInfo.bank_area,
          bank_city: storeInfo.bank_city,
          bank_id_card_front: handleImg(storeInfo.bank_id_card_front),
          bank_id_card_back: handleImg(storeInfo.bank_id_card_back),
          bank_hand_image: handleImg(storeInfo.bank_hand_image),
          opening_permit_image: handleImg(storeInfo.opening_permit_image),
          bank_card_image: handleImg(storeInfo.bank_card_image),
          ali_account: storeInfo.ali_account,
          email: storeInfo.email,
          bank_mobile: storeInfo.bank_mobile || '',
          registered_capital: storeInfo.registered_capital || 0,
          bf_industry_code: storeInfo.bf_industry_code,
        },
        fetchPolicy: "no-cache",
      });
      if (data.addStoreBase) {
        Message.success("提交成功,请等待审核");
        localStorage.removeItem(LOCAL_KEY);
        setInterval(null);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {}
  };

  const verification = (rule) => {
    // 通过后不需要再验证
    if (!first) {
      return true;
    } else {
      for (let item of rule) {
        if (item.rule) {
          Message.info(item.title);
          return false;
        }
      }
      return true;
    }
  };

  // 行业归属
  const changeIndustry = (code) => {
    setStoreInfo({
      ...storeInfo,
      bf_industry_code: code
    })
  }

  // 店铺类目
  const changeCategroy = (value) => {
    const a = storeCategroy.filter((i) => {
      return i.name == value;
    });
    setStoreInfo({
      ...storeInfo,
      store_category_name: value,
      store_category_id: a[0].id,
    });
  };

  const changeBankName = async (value, type) => {
    if (type == 1) {
      const a = bankName.filter((i) => {
        return i.name == value;
      });
      setStoreInfo({
        ...storeInfo,
        bank_name: value,
        bank_branch: "",
      });
      setCategroyCode(a[0].code);
    } else {
      const a = bankArr.filter((i) => {
        return i.name == value;
      });
      setStoreInfo({
        ...storeInfo,
        bank_branch: value,
        bank_code: a[0].code,
      });
    }
  };

  // 通过地址获取经纬度
  const submit = async () => {
    if (step == 0) {
      if (JSON.stringify(storeInfo.settle_status) == "null") {
        Message.info("请选择入驻方式");
      } else {
        setStep(1);
      }
    }
    if (step == 1 && verification(rule1)) {
      setStep(2);
    }
    if (step == 2 && verification(rule2)) {
      setStep(3);
      queryAddress(1, "");
      queryBankCategories();
    }
    if (step == 3 && verification(rule3)) {
      if (!first) {
        Message.info("不允许再次提交");
        return;
      }
      // 0典栈待审核，3蜂鸟待审核，4带银行审核，0未驳回, 7合利宝审核
      if (
        storeBase.status == 0 ||
        storeBase.status == 3 ||
        storeBase.status == 4 ||
        storeBase.reject_type == 0 ||
        storeBase.status == 7
      ) {
        Message.info("正在审核中，请勿重复提交");
      } else {
        //银行卡是否有效
        // const {data} = await axios.get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=${storeInfo.bank_account}&cardBinCheck=true`);
        // console.log('checkData = ', data)
        // if (data && data.validated) {
        const { data } = await axios.get(
          `https://restapi.amap.com/v3/geocode/geo?key=75060fae7d3d453c790b609a312a7a08&address=${
            storeInfo.city + storeInfo.area + storeInfo.address
          }&city=${storeInfo.city}`
        );
        const d = data.geocodes[0].location.indexOf(",");
        const lng = data.geocodes[0].location.slice(0, d);
        const lat = data.geocodes[0].location.slice(d + 1);
        submitMutate(lng, lat);
        // } else {
        //     Message.info('请填写有的效银行卡号')
        // }
      }
    }
  };

  const queryAddress = async (type, code) => {
    try {
      const { data } = await client.query({
        query: UNIONPAY_REGIONS,
        variables: {
          type: type,
          parent_code: code,
        },
        fetchPolicy: "no-cache",
      });
      if (data) {
        if (type == 1) {
          setProvinceArr(data.unionpayRegions);
          if (storeInfo.bank_province) {
            const a = data.unionpayRegions.filter((i) => {
              return i.name == storeInfo.bank_province;
            });
            queryAddress(2, a[0].code);
          }
        } else if (type == 2) {
          setCityArr(data.unionpayRegions);
          if (storeInfo.bank_city) {
            const a = data.unionpayRegions.filter((i) => {
              return i.name == storeInfo.bank_city;
            });
            queryAddress(3, a[0].code);
          }
        } else {
          setAreaArr(data.unionpayRegions);
          if (storeInfo.bank_area) {
            const a = data.unionpayRegions.filter((i) => {
              return i.name == storeInfo.bank_area;
            });
            setCityCode(a[0].code);
          }
        }
      }
    } catch (error) {}
  };

  const changeSame = (e) => {
    if (storeInfo.corporation && storeInfo.id_card_no && storeInfo.mobile) {
      const a = e.target.checked;
      setSame(a);
      setStoreInfo({
        ...storeInfo,
        contact_name: a ? storeInfo.corporation : "",
        contact_id_card: a ? storeInfo.id_card_no : "",
        contact_mobile: a ? storeInfo.mobile : "",
        contact_card_front: a ? storeInfo.id_card_front.map(t => ({...t})) : [],
        contact_card_back: a ? storeInfo.id_card_back.map(t => ({...t})) : [],
        contact_card_address: a ? storeInfo.id_card_address : '',
        contact_card_valid_start: a ? storeInfo.id_card_valid_start : null,
        contact_card_valid_end: a ? storeInfo.id_card_valid_end : null,
      });
      setIdCard2(a ? longIdCard : false)
    } else {
      Message.info("请先填写完整的法人信息");
    }
  };

  const getCode = async (type) => {
    try {
      const { data } = await client.query({
        query: CODE,
        variables: {
          type: type,
          settle_status: storeInfo.settle_status,
        },
        fetchPolicy: "no-cache",
      });
      if (data) {
        if (type == 1) {
          setMccCode(data.code);
        } else {
          setIndustry(data.code);
        }
      }
    } catch (error) {}
  };

  const queryBankCategories = async () => {
    try {
      const { data } = await client.query({
        query: UNIONPAY_BANK_CATEGORIES,
        fetchPolicy: "no-cache",
      });
      if (data) {
        setBankName(data.unionpayBankCategories);
        if (storeInfo.bank_name) {
          const a = data.unionpayBankCategories.filter((i) => {
            return i.name == storeInfo.bank_name;
          });
          setCategroyCode(a[0].code);
        }
      }
    } catch (error) {}
  };

  const changeAddress = (v, type) => {
    if (type == 1) {
      setStoreInfo({
        ...storeInfo,
        bank_province: v,
        bank_city: "",
        bank_area: "",
        bank_branch: "",
      });
      queryAddress(
        2,
        provinceArr.find((i) => {
          return i.name == v;
        }).code
      );
    } else if (type == 2) {
      setStoreInfo({
        ...storeInfo,
        bank_city: v,
        bank_area: "",
        bank_branch: "",
      });
      queryAddress(
        3,
        cityArr.find((i) => {
          return i.name == v;
        }).code
      );
    } else {
      setStoreInfo({
        ...storeInfo,
        bank_area: v,
        bank_branch: "",
      });
      setCityCode(
        areaArr.find((i) => {
          return i.name == v;
        }).code
      );
    }
  };

  const queryAgent = async (e) => {
    if (e) {
      try {
        const { data } = await client.query({
          query: AGENT,
          variables: {
            agent_mobile: e,
          },
          fetchPolicy: "no-cache",
        });
        if (!data.agent) {
          Message.info("没找到该代理手机号");
          changeData("code", "");
        }
      } catch (error) {}
    }
  };

  // 审核状态
  const getStatus = () => {
    switch (storeBase.status) {
      case 0:
        return "审核中";
      case 1:
        return "审核通过";
      case 2:
        return "审核不通过";
      case 3:
        return "审核中";
      case 4:
        return "待银行审核";
      case 5:
        return "审核结果：资料未完善，请在补充资料提交打款验证！";
      case 6:
        return "审核结果：资料未完善，请在补充资料提交商户授权认证！";
      case 7:
        return "待银行审核";
      default:
        break;
    }
  };

  // 显示补充资料弹窗
  const moreData = async () => {
    try {
      const { data } = await client.query({
        query: ADDITIONALINFO,
        fetchPolicy: "no-cache",
      });
      if (data) {
        const a = data.additionalInfo;
        setVerifyAmount(a.verify_amount);
        setAuthImage(imgData(a.auth_image && a.auth_image.url, "auth_image"));
      }
    } catch (error) {}
    setMoreData(true);
  };

  //提交补充资料
  const upData = () => {
    if (storeBase.status == 5) {
      if (!verifyAmount) {
        Message.info("请填写验证金额");
      } else if (
        verifyAmount.toString().indexOf(".") !== -1 &&
        verifyAmount.toString().split(".")[1].length > 2
      ) {
        Message.info("请填写最多两位小数的验证金额");
      } else {
        queryAdditional();
      }
    } else {
      if (JSON.stringify(authImage) == "null" || authImage.length < 1) {
        Message.info("请上传认证成功的截图");
      } else {
        queryAdditional();
      }
    }
  };

  const showExample = (img) => {
    Modal.info({
      icon: null,
      content: (
        <img
          style={{ textAlign: "center", width: "530px" }}
          src={require(`../../assets/images/example/${img}.jpg`)}
          alt=""
        />
      ),
      width: 600,
    });
  };

  const queryAdditional = async () => {
    try {
      const { data } = await client.mutate({
        mutation: ADDITIONAL,
        variables: {
          settle_status: storeInfo.settle_status,
          verify_amount: verifyAmount ? verifyAmount : 0,
          auth_image: handleImg(authImage) ? handleImg(authImage) : "",
        },
        fetchPolicy: "no-cache",
      });
      if (data.additional) {
        Message.success("提交成功");
        setMoreData(false);
        window.location.reload();
      }
    } catch (error) {}
  };

  const changeList = (v, i) => {
    if (i == 1) {
      setStoreInfo({
        ...storeInfo,
        province: v,
        city: "",
        area: "",
      });
    } else {
      setStoreInfo({
        ...storeInfo,
        city: v,
        area: "",
      });
    }
  };

  const changeTime = (value, dateString, start, end) => {
    setStoreInfo({
      ...storeInfo,
      [start]: dateString[0],
      [end]: dateString[1],
    });
  };

  const changeBox = (e, type, start, end) => {
    if (type == 1) {
      setLongCredit(e.target.checked);
    } else {
      if (start == 'contact_card_valid_start') {
        setIdCard2(e.target.checked);
      } else {
        setIdCard(e.target.checked);
      }
    }

    if (e.target.checked) {
      if (type == 2) {
        const idCardStart = storeInfo[start] || "2099-12-30";
        setStoreInfo({
          ...storeInfo,
          [start]: idCardStart,
          [end]: "2099-12-30",
        });
      } else {
        setStoreInfo({
          ...storeInfo,
          [start]: "2099-12-30",
          [end]: "2099-12-30",
        });
      }
    } else {
      setStoreInfo({
        ...storeInfo,
        [start]: "",
        [end]: "",
      });
    }
  };

  const getImageType = (name) => {
    switch (name) {
      case "id_card_front":
      case "id_card_back":
      case "contact_card_front":
      case "contact_card_back":
      case "bank_id_card_front":
        return 0; //身份证
      case "bank_card_image":
        return 1; //银行卡
      case "business_license":
        return 2; //营业执照
      default:
        break;
    }
  };
  const image2Text = async (name, arr) => {
    try {
      if (arr && arr.length > 0) {
        const { data } = await client.query({
          query: IMAGE2TEXT,
          variables: {
            type: getImageType(name),
            url: handleImg(arr),
            side:
              name.indexOf("_front") != -1
                ? "front"
                : name.indexOf("_back") != -1
                ? "back"
                : "",
          },
          fetchPolicy: "no-cache",
        });
        console.log("图片识别", arr);
        console.log("data11:", data);

        if (data && data.image2Text) {
          switch (name) {
            case "business_license": //营业执照
              const company_name = data.image2Text.company_name;
              const credit_code = data.image2Text.credit_code;
              // const start = data.image2Text.business_license_valid_start;
              // const end = data.image2Text.business_license_valid_end;
              const startText =
                // storeInfo.settle_status == 0
                //   ? "2099-12-30"
                //   : !isEmpty(start) && start != "无"
                //     ? moment(start, "YYYY年MM月DD日").format("YYYY-MM-DD")
                //     : "";
                "2099-12-30";
              const endText =
                // storeInfo.settle_status == 0 ? "2099-12-30"
                //   : (!isEmpty(end) && end != "无") ? end == '长期' ? '2099-12-30' : moment(end, "YYYY年MM月DD日").format("YYYY-MM-DD")
                //     : "";
                "2099-12-30";
              setStoreInfo({
                ...storeInfo,
                business_license: arr,
                company_name:
                  !isEmpty(company_name) && company_name != "无"
                    ? company_name
                    : "",
                credit_code:
                  !isEmpty(credit_code) && credit_code != "无"
                    ? credit_code
                    : "",
                business_license_valid_start: startText,
                business_license_valid_end: endText,
              });
              // 个体户不用识别
              // if (storeInfo.settle_status !== 0) {
              //   setLongCredit(false);
              // }
              break;
            case "id_card_front": //step==2身份证正面照
              const corporation = data.image2Text.name;
              const id_card_no = data.image2Text.id_card_no;
              setStoreInfo({
                ...storeInfo,
                id_card_front: arr,
                corporation:
                  !isEmpty(corporation) && corporation != "无"
                    ? corporation
                    : "",
                id_card_address: data.image2Text.id_card_address,
                id_card_no:
                  !isEmpty(id_card_no) && id_card_no != "无" ? id_card_no : "",
              });
              break;
            case "id_card_back": //step==2身份证背面照
              const idStart = data.image2Text.id_card_valid_start;
              const idEnd = data.image2Text.id_card_valid_end;
              setStoreInfo({
                ...storeInfo,
                id_card_back: arr,
                id_card_valid_start:
                  !isEmpty(idStart) && idStart != "无"
                    ? moment(idStart, "YYYYMMDD").format("YYYY-MM-DD")
                    : "",
                id_card_valid_end:
                  !isEmpty(idEnd) && idEnd != "无"
                    ? idEnd == "长期"
                      ? "2099-12-30"
                      : moment(idEnd, "YYYYMMDD").format("YYYY-MM-DD")
                    : "",
              });
              setIdCard(false);
              break;
            case "contact_card_front": //step==2 受益人身份证正面照
              const _name = data.image2Text.name;
              const id_card_no2 = data.image2Text.id_card_no;
              setStoreInfo({
                ...storeInfo,
                contact_card_front: arr,
                contact_name: !isEmpty(_name) && _name != "无" ? _name : "",
                contact_card_address: data.image2Text.id_card_address,
                contact_id_card: !isEmpty(id_card_no2) && id_card_no2 != "无" ? id_card_no2 : "",
              });
              break;
            case "contact_card_back": //step==2 受益人身份证背面照
              const idStart2 = data.image2Text.id_card_valid_start;
              const idEnd2 = data.image2Text.id_card_valid_end;
              setStoreInfo({
                ...storeInfo,
                contact_card_back: arr,
                contact_card_valid_start:
                  !isEmpty(idStart2) && idStart2 != "无"
                    ? moment(idStart2, "YYYYMMDD").format("YYYY-MM-DD")
                    : "",
                contact_card_valid_end:
                  !isEmpty(idEnd2) && idEnd2 != "无"
                    ? idEnd2 == "长期"
                      ? "2099-12-30"
                      : moment(idEnd2, "YYYYMMDD").format("YYYY-MM-DD")
                    : "",
              });
              setIdCard2(false);
              break;
            case "bank_id_card_front": //step==3开户人身份证正面照
              const baicn = data.image2Text.id_card_no;
              setStoreInfo({
                ...storeInfo,
                bank_id_card_front: arr,
                bank_account_id_card_no:
                  !isEmpty(baicn) && baicn != "无" ? baicn : "",
              });
              break;
            case "bank_card_image": //step==3结算银行卡照
              const bank_name = data.image2Text.bank_name;
              let bank = null;
              if (bank_name && bankName) {
                bank = bankName.find((i) => {
                  return i.name.indexOf(bank_name) != -1;
                });
              }
              setStoreInfo({
                ...storeInfo,
                bank_card_image: arr,
                bank_account: data.image2Text.bank_account,
                bank_name: bank ? bank.name : "",
              });
              setCategroyCode(bank ? bank.code : "");
              break;
            default:
              break;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="index-container">
      <div className="index-wrapper">
        <div className="category-content-top">
          {storeBase.status != 1 && <Steps
            size="small"
            labelPlacement="vertical"
            current={step}
            style={{ marginBottom: 30, width: 400 }}
          >
            <Step title="预览所需材料" />
            <Step title="填写店铺信息" />
            <Step title="填写个人信息" />
            <Step title="填写银行信息" />
          </Steps>}
          <div className="category-audit-tip_status">
            {storeBase.status == null ? (
              <span className="red">未认证</span>
            ) : (
              <span className="red">
                {storeBase.status == 5 || storeBase.status == 6
                  ? infoStatus.status == 0 && infoStatus.auth_image
                    ? "审核中"
                    : getStatus()
                  : getStatus()}
              </span>
            )}
            {storeBase.status == 2 &&
            (storeBase.reject_reason || infoStatus.reject_reason) ? (
              <span className="rej">
                理由：{storeBase.reject_reason || infoStatus.reject_reason}
              </span>
            ) : null}
            {storeBase.status !== null &&
              (storeBase.status == 5 || storeBase.status == 6) &&
              infoStatus.status == 0 &&
              JSON.stringify(infoStatus.auth_image) == "null" && (
                <span
                  className="blue"
                  onClick={() => moreData(storeBase.status)}
                >
                  去提交
                </span>
              )}
          </div>
        </div>
        {/*第一步*/}
        {step == 0 && (
          <FirstStep
            first={!first}
            showMoreData={() => moreData()}
            settleStatus={storeInfo.settle_status}
            status={storeBase.status}
            changeIdx={(i) => chooseSettle(i)}
          />
        )}
        {step !== 0 && (
          <div className="category-content">
            {/*第二步*/}
            {step == 1 && (
              <div>
                <div className="category-content_wrapper">
                  <div className="title">
                    店铺简称 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="请填写店铺简称"
                      value={storeInfo.short_name}
                      maxLength={20}
                      className="my-input"
                      onChange={(e) => changeData("short_name", e.target.value)}
                    />
                    <div className="suffix">
                      {storeInfo.short_name &&
                        storeInfo.short_name.length + "/20"}
                    </div>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    商家类目 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Select
                      showSearch
                      disabled={!first}
                      className="my-input"
                      value={storeInfo.store_category_name}
                      placeholder="请选择店铺经营的类目"
                      onChange={(e) => changeCategroy(e)}
                    >
                      {storeCategroy.map((i) => {
                        return (
                          <Option value={i.name} key={i.id}>
                            {i.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                {/* 店铺类型 */}
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title">
                    店铺类型 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <div className="store-type">
                      <div className="radio-group">
                        <Radio
                          className="radio"
                          value={0}
                          checked={storeInfo.product_type == 0}
                          onChange={() => {
                            changeData("product_type", 0);
                          }}
                        >
                          实物商品（需配送发货）
                        </Radio>
                        <Radio
                          className="radio"
                          value={1}
                          style={{ marginTop: 10 }}
                          checked={storeInfo.product_type == 1}
                          onChange={() => {
                            changeData("product_type", 1);
                          }}
                        >
                          电子卡券（系统自动生成券码，线上核销）
                        </Radio>
                      </div>
                      <div
                        className="tip2"
                        style={{
                          marginLeft: 24,
                          marginTop: 10,
                          marginBottom: 0,
                          color: "#F4981D",
                        }}
                      >
                        温馨提示：商品类型认证成功后，不允许修改
                      </div>
                    </div>
                  </div>
                </div>

                {/* 人均消费 */}
                <div className="category-content_wrapper">
                  <div className="title">
                    人均消费 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      type="number"
                      disabled={!first}
                      placeholder=""
                      value={storeInfo.average_spend}
                      className="my-input"
                      onChange={(e) =>
                        changeData("average_spend", e.target.value)
                      }
                    />
                  </div>
                </div>

                {/* 起送价 */}
                {storeInfo.product_type == 0 ? (
                  <div className="category-content_wrapper">
                    <div className="title">
                      起送价 <i className="red">*</i>
                    </div>
                    <div className="content">
                      <Input
                        type="number"
                        disabled={!first}
                        placeholder=""
                        value={storeInfo.lowest_delivery_price}
                        className="my-input"
                        onChange={(e) =>
                          changeData("lowest_delivery_price", e.target.value)
                        }
                        style={{ marginLeft: 15 }}
                      />
                    </div>
                  </div>
                ) : null}

                {/* 联系人姓名 */}
                <div className="category-content_wrapper">
                  <div className="title">
                    联系人姓名 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      type="text"
                      disabled={!first}
                      placeholder="请填写联系人姓名"
                      value={storeInfo.contact_name}
                      className="my-input"
                      onChange={(e) =>
                        changeData("contact_name", e.target.value)
                      }
                      style={{ marginLeft: -16 }}
                    />
                  </div>
                </div>

                <div className="category-content_wrapper">
                  <div className="title">
                    经营类别 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Select
                      showSearch
                      disabled={!first}
                      className="my-input"
                      value={
                        storeInfo.mcc_name
                          ? storeInfo.mcc_name
                          : "请选择经营类别"
                      }
                      onFocus={() => getCode(1)}
                      onChange={(e) => {
                        setStoreInfo({
                          ...storeInfo,
                          mcc_code: mccCode.filter((i) => {
                            return i.name == e;
                          })[0].code,
                          mcc_name: e,
                        });
                      }}
                    >
                      {mccCode.map((i, t) => {
                        return (
                          <Option value={i.name} key={`${i.code}-${t}`}>
                            {i.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    行业归属 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Select
                      showSearch
                      disabled={!first}
                      className="my-input"
                      value={storeInfo.bf_industry_code}
                      placeholder="请选择所属行业"
                      onChange={(e) => changeIndustry(e)}
                    >
                      {storeIndustry.map((i) => {
                        return (
                          <Option value={i.code} key={i.id}>
                            {i.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    行业类目 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Select
                      showSearch
                      disabled={!first}
                      className="my-input"
                      value={
                        storeInfo.industry_name
                          ? storeInfo.industry_name
                          : "请选择行业类目"
                      }
                      onFocus={() => getCode(2)}
                      onChange={(e) => {
                        setStoreInfo({
                          ...storeInfo,
                          industry_code: industry.filter((i) => {
                            return i.name == e;
                          })[0].code,
                          industry_name: e,
                        });
                      }}
                    >
                      {industry.map((i, t) => {
                        return (
                          <Option value={i.name} key={`${i.code}-${t}`}>
                            {i.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <Address
                  title={"店铺地址"}
                  province={storeInfo.province}
                  city={storeInfo.city}
                  area={storeInfo.area}
                  provinceName={"province"}
                  cityName={"city"}
                  areaName={"area"}
                  address={storeInfo.address}
                  showAddress={true}
                  disabled={!first}
                  changeList={(v, i) => changeList(v, i)}
                  changeData={(name, value) => changeData(name, value)}
                />
                <div className="category-content_wrapper">
                  <div className="title">
                    客服手机 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      type="text"
                      disabled={!first}
                      placeholder="请填写客服手机号"
                      value={storeInfo.hot_line}
                      className="my-input"
                      onChange={(e) => changeData("hot_line", e.target.value)}
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    支付宝账号 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      type="text"
                      disabled={!first}
                      placeholder="请填写支付宝账号"
                      value={storeInfo.ali_account}
                      className="my-input"
                      onChange={(e) =>
                        changeData("ali_account", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 52 }}>
                    推荐人手机号 <i className="red"></i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!isFirstBase || isPlatformAudit}
                      placeholder="请填写推荐人的手机号码，若无可不填"
                      value={storeInfo.code}
                      maxLength={11}
                      className="my-input"
                      onBlur={(e) => queryAgent(e.target.value)}
                      onChange={(e) => changeData("code", e.target.value)}
                    />
                  </div>
                </div>

                {storeInfo.settle_status !== 1 && (
                  <>
                    <div
                      className="category-content_wrapper"
                      style={{ alignItems: "flex-start" }}
                    >
                      <div className="title">
                        营业执照 <i className="red">*</i>
                      </div>
                      <BusinessImg
                        OSSData={OSSData}
                        listChange={(arr) => {
                          changeData("business_license", arr);
                        }}
                        onSuccess={(arr) => {
                          image2Text("business_license", arr);
                        }}
                        fileList={storeInfo.business_license}
                        disabled={!first}
                      />
                      <div className="img-tip">
                        • 上传JPG、PNG格式，图片小于1M；
                        <br />
                        • 营业执照未过期；
                        <br />
                        • 需文字清晰、边框完整、露出国徽；
                        <br />• 复印件需加盖公章。
                        <p
                          style={{ color: "#048ad3", cursor: "pointer" }}
                          onClick={() => showExample(3)}
                        >
                          示例
                        </p>
                      </div>
                    </div>
                    <div className="category-content_wrapper">
                      <div className="title">
                        公司名称 <i className="red">*</i>
                      </div>
                      <div className="content">
                        <Input
                          disabled={!first}
                          placeholder="请填写营业执照一致的名称"
                          value={storeInfo.company_name}
                          maxLength={50}
                          className="my-input"
                          onChange={(e) =>
                            changeData("company_name", e.target.value)
                          }
                        />
                        <div className="suffix">
                          {storeInfo.company_name &&
                            storeInfo.company_name.length + "/50"}
                        </div>
                      </div>
                    </div>
                    <div className="category-content_wrapper">
                      <div className="title">
                        统一社会
                        <br />
                        信用代码 <i className="red">*</i>
                      </div>
                      <div className="content">
                        <Input
                          disabled={!first}
                          placeholder="统一社会信用代码"
                          value={storeInfo.credit_code}
                          className="my-input"
                          maxLength={18}
                          onChange={(e) =>
                            changeData("credit_code", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="category-content_wrapper">
                      <div className="title">
                        营业执照
                        <br />
                        有效期 <i className="red">*</i>
                      </div>
                      <div className="content">
                        <RangePicker
                          value={[
                            storeInfo.business_license_valid_start
                              ? moment(storeInfo.business_license_valid_start)
                              : "",
                            storeInfo.business_license_valid_end
                              ? moment(storeInfo.business_license_valid_end)
                              : "",
                          ]}
                          disabled={!first || longCredit}
                          onChange={(value, dateString) =>
                            changeTime(
                              value,
                              dateString,
                              "business_license_valid_start",
                              "business_license_valid_end"
                            )
                          }
                        />
                        <div style={{ marginLeft: 50, display: "inline" }}>
                          <Checkbox
                            checked={longCredit}
                            disabled={!first}
                            onChange={(e) =>
                              changeBox(
                                e,
                                1,
                                "business_license_valid_start",
                                "business_license_valid_end"
                              )
                            }
                          >
                            长期
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="category-content_wrapper">
                      <div className="title">
                        注册资本 <i className="red">*</i>
                      </div>
                      <div className="content">
                        <Input
                          type="number"
                          disabled={!first}
                          placeholder="请填写注册资本"
                          value={storeInfo.registered_capital}
                          className="my-input"
                          onChange={(e) =>
                            changeData("registered_capital", e.target.value)
                          }
                        />
                        <div className="suffix">万元</div>
                      </div>
                    </div>
                  </>
                )}
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 84 }}>
                    门头照 <i className="red">*</i>
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("house_num", arr);
                    }}
                    fileList={storeInfo.house_num}
                    disabled={!first}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式，图片小于1M；
                    <br />
                    • 需拍摄完整牌匾、门框；
                    <br />• 确保图像清晰；
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(4)}
                    >
                      示例
                    </p>
                  </div>
                </div>

                {/* 新增字段 店铺头像 */}
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 68 }}>
                    店铺头像 <i className="red">*</i>
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("cover", arr);
                    }}
                    fileList={storeInfo.cover}
                    disabled={!first}
                  />
                  <div className="img-tip" style={{ marginLeft: 100 }}>
                    • 建议上传140*140px或比例1：1;
                    <br />• JPG、PNG格式，图片小于1M;
                  </div>
                </div>

                {/* 新增字段 店铺轮播图 */}
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 52 }}>
                    店铺轮播图
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("images", arr);
                    }}
                    fileList={storeInfo.images}
                    disabled={!first}
                    maxLen={5}
                  />
                  <div className="img-tip" style={{ marginLeft: 0 }}>
                    • 建议上传710*300px或比例7:3;
                    <br />• JPG、PNG格式，图片小于1M；最多上传5张图片;
                  </div>
                </div>

                {/* 新增字段 介绍视频 */}
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 68 }}>
                    介绍视频 <i className="red"></i>
                  </div>
                  <BusinessImg
                    type={2}
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("video", arr);
                    }}
                    fileList={storeInfo.video}
                    disabled={!first}
                  />
                  <div className="img-tip" style={{ marginLeft: 100 }}>
                    • 选填，建议上传710*300px或比例7:3;
                    <br />• MP4格式；只允许上传1个视频;
                  </div>
                </div>

                {/* 新增字段 店铺公告 */}
                <div className="category-content_wrapper">
                  <div className="title">
                    店铺公告 <i className="red"></i>
                  </div>
                  <div className="content">
                    <Input
                      type="text"
                      disabled={!first}
                      placeholder="请输入店铺公告"
                      value={storeInfo.notice}
                      className="my-input"
                      onChange={(e) => changeData("notice", e.target.value)}
                    />
                  </div>
                </div>

                {storeInfo.settle_status !== 1 && (
                  <>
                    <div
                      className="category-content_wrapper"
                      style={{ alignItems: "flex-start" }}
                    >
                      <div className="title">
                        收银台照 <i className="red">*</i>
                      </div>
                      <BusinessImg
                        OSSData={OSSData}
                        listChange={(arr) => {
                          changeData("checkout_counter", arr);
                        }}
                        fileList={storeInfo.checkout_counter}
                        disabled={!first}
                      />
                      <div className="img-tip">
                        • 上传JPG、PNG格式，图片小于1M；
                        <br />
                        • 确保图像清晰；
                        <br />
                        <p
                          style={{ color: "#048ad3", cursor: "pointer" }}
                          onClick={() => showExample(6)}
                        >
                          示例
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 84 }}>
                    店内照 <i className="red">*</i>
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("store_inner", arr);
                    }}
                    fileList={storeInfo.store_inner}
                    disabled={!first}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式，图片小于1M；
                    <br />
                    • 需拍摄完整店内环境；
                    <br />
                    • 确保图像清晰；
                    <br />• 无店内照可上传租赁协议；
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(1)}
                    >
                      示例
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/*第三步*/}
            {step == 2 && (
              <div>
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 38 }}>
                    身份证正面照 <i className="red">*</i>
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("id_card_front", arr);
                    }}
                    onSuccess={(arr) => {
                      image2Text("id_card_front", arr);
                    }}
                    fileList={storeInfo.id_card_front}
                    disabled={!first}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式，图片小于1M；
                    <br />
                    • 证件头像清晰，文字数字清晰可辨；
                    <br />
                    • 个体户或企业用户上传法人身份证；
                    <br />
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(8)}
                    >
                      示例
                    </p>
                  </div>
                </div>
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 38 }}>
                    身份证背面照 <i className="red">*</i>
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("id_card_back", arr);
                    }}
                    onSuccess={(arr) => {
                      image2Text("id_card_back", arr);
                    }}
                    fileList={storeInfo.id_card_back}
                    defaultImg={require("../../assets/images/1.png")}
                    disabled={!first}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式，图片小于1M；
                    <br />
                    • 身份证未过期；
                    <br />
                    • 个体户或企业用户上传法人身份证；
                    <br />
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(7)}
                    >
                      示例
                    </p>
                  </div>
                </div>
                {/* {storeInfo.settle_status == 0 && (
                  <div
                    className="category-content_wrapper"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="title" style={{ marginRight: 38 }}>
                      手持身份证照 <i className="red">*</i>
                    </div>
                    <BusinessImg
                      OSSData={OSSData}
                      listChange={(arr) => {
                        changeData("id_card_hand", arr);
                      }}
                      fileList={storeInfo.id_card_hand}
                      defaultImg={require("../../assets/images/1.png")}
                      disabled={!first}
                    />
                    <div className="img-tip">
                      • 上传JPG、PNG格式，图片小于1M；
                      <br />
                      • 证件头像清晰，文字数字清晰可辨；
                      <br />• 手持身份证人脸清晰；
                      <p
                        style={{ color: "#048ad3", cursor: "pointer" }}
                        onClick={() => showExample(9)}
                      >
                        示例
                      </p>
                    </div>
                  </div>
                )} */}
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 54 }}>
                    法人姓名 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="个体户或企业用户请填写法人姓名"
                      value={storeInfo.corporation}
                      className="my-input"
                      maxLength={20}
                      onChange={(e) =>
                        changeData("corporation", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 22 }}>
                    法人身份证号 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="个体户或企业用户请填写法人身份证号"
                      value={storeInfo.id_card_no}
                      className="my-input"
                      maxLength={18}
                      onChange={(e) => changeData("id_card_no", e.target.value)}
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 22 }}>
                    法人身份证地址 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="个体户或企业用户请填写法人法人身份证地址"
                      value={storeInfo.id_card_address}
                      className="my-input"
                      onChange={(e) =>
                        changeData("id_card_address", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 38 }}>
                    身份证有效期 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <RangePicker
                      value={[
                        storeInfo.id_card_valid_start
                          ? moment(storeInfo.id_card_valid_start)
                          : "",
                        storeInfo.id_card_valid_end
                          ? moment(storeInfo.id_card_valid_end)
                          : "",
                      ]}
                      disabled={!first || longIdCard}
                      onChange={(value, dateString) =>
                        changeTime(
                          value,
                          dateString,
                          "id_card_valid_start",
                          "id_card_valid_end"
                        )
                      }
                    />
                    <div style={{ marginLeft: 50, display: "inline" }}>
                      <Checkbox
                        checked={longIdCard}
                        disabled={!first}
                        onChange={(e) =>
                          changeBox(
                            e,
                            2,
                            "id_card_valid_start",
                            "id_card_valid_end"
                          )
                        }
                      >
                        长期
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 22 }}>
                    法人手机号码 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="个体户或企业用户请填写法人手机号码"
                      value={storeInfo.mobile}
                      className="my-input"
                      maxLength={11}
                      onChange={(e) => changeData("mobile", e.target.value)}
                    />
                  </div>
                </div>
                {storeInfo.settle_status !== 1 && (
                  <div className="category-content_wrapper">
                    <div className="title" style={{ marginRight: 22 }}>
                      邮箱 <i className="red">*</i>
                    </div>
                    <div className="content">
                      <Input
                        disabled={!first}
                        placeholder="请输入邮箱"
                        value={storeInfo.email}
                        className="my-input"
                        onChange={(e) => changeData("email", e.target.value)}
                      />
                    </div>
                  </div>
                )}
                <div className="category-content_wrapper">
                  {/* <Checkbox
                    checked={same}
                    disabled={!first}
                    style={{ fontSize: 16 }}
                    onChange={(e) => changeSame(e)}
                  >
                    受益人与法人信息一致
                  </Checkbox> */}
                  <span style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.65)' }}>受益人{ storeInfo.settle_status != 2 && '（非必填）'}</span>
                </div>
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 38 }}>
                    受益人身份证正面照 {storeInfo.settle_status == 2 && <i className="red">*</i>}
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("contact_card_front", arr);
                    }}
                    onSuccess={(arr) => {
                      image2Text("contact_card_front", arr);
                    }}
                    fileList={storeInfo.contact_card_front}
                    disabled={!first}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式，图片小于1M；
                    <br />
                    • 证件头像清晰，文字数字清晰可辨；
                    <br />
                    • 个体户或企业用户上传法人身份证；
                    <br />
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(8)}
                    >
                      示例
                    </p>
                  </div>
                </div>
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 38 }}>
                    受益人身份证背面照 {storeInfo.settle_status == 2 && <i className="red">*</i>}
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("contact_card_back", arr);
                    }}
                    onSuccess={(arr) => {
                      image2Text("contact_card_back", arr);
                    }}
                    fileList={storeInfo.contact_card_back}
                    defaultImg={require("../../assets/images/1.png")}
                    disabled={!first}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式，图片小于1M；
                    <br />
                    • 身份证未过期；
                    <br />
                    • 个体户或企业用户上传法人身份证；
                    <br />
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(7)}
                    >
                      示例
                    </p>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 54 }}>
                    受益人姓名 {storeInfo.settle_status == 2 && <i className="red">*</i>}
                  </div>
                  <div className="content">
                    <Input
                      placeholder="请填写受益人姓名"
                      value={storeInfo.contact_name}
                      className="my-input"
                      maxLength={20}
                      disabled={!first}
                      onChange={(e) =>
                        changeData("contact_name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 22 }}>
                    受益人身份证号 {storeInfo.settle_status == 2 && <i className="red">*</i>}
                  </div>
                  <div className="content">
                    <Input
                      placeholder="请填写受益人身份证号"
                      value={storeInfo.contact_id_card}
                      className="my-input"
                      maxLength={18}
                      disabled={!first}
                      onChange={(e) =>
                        changeData("contact_id_card", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 22 }}>
                    受益人身份证地址 {storeInfo.settle_status == 2 && <i className="red">*</i>}
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="个体户或企业用户请填写受益人身份证地址"
                      value={storeInfo.contact_card_address}
                      className="my-input"
                      onChange={(e) =>
                        changeData("contact_card_address", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 38 }}>
                    受益人身份证有效期 {storeInfo.settle_status == 2 && <i className="red">*</i>}
                  </div>
                  <div className="content">
                    <RangePicker
                      value={[
                        storeInfo.contact_card_valid_start
                          ? moment(storeInfo.contact_card_valid_start)
                          : "",
                        storeInfo.contact_card_valid_end
                          ? moment(storeInfo.contact_card_valid_end)
                          : "",
                      ]}
                      disabled={!first || longIdCard2}
                      onChange={(value, dateString) =>
                        changeTime(
                          value,
                          dateString,
                          "contact_card_valid_start",
                          "contact_card_valid_end"
                        )
                      }
                    />
                    <div style={{ marginLeft: 50, display: "inline" }}>
                      <Checkbox
                        checked={longIdCard2}
                        disabled={!first}
                        onChange={(e) =>
                          changeBox(
                            e,
                            2,
                            "contact_card_valid_start",
                            "contact_card_valid_end"
                          )
                        }
                      >
                        长期
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 54 }}>
                    受益人电话 {storeInfo.settle_status == 2 && <i className="red">*</i>}
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="请填写手机号码"
                      value={storeInfo.contact_mobile}
                      className="my-input"
                      type="number"
                      onChange={(e) =>
                        changeData("contact_mobile", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 54 }}>
                    受益人邮箱 {storeInfo.settle_status == 2 && <i className="red">*</i>}
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="请填写受益人邮箱"
                      value={storeInfo.contact_email}
                      className="my-input"
                      onChange={(e) =>
                        changeData("contact_email", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {/*第四步*/}
            {step == 3 && (
              <div>
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 38 }}>
                    开户人身份证
                    <br />
                    正面照 <i className="red">*</i>
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("bank_id_card_front", arr);
                    }}
                    onSuccess={(arr) => {
                      image2Text("bank_id_card_front", arr);
                    }}
                    fileList={storeInfo.bank_id_card_front}
                    disabled={!first}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式，图片小于1M；
                    <br />
                    • 证件头像清晰，文字数字清晰可辨；
                    <br />
                    • 对公账户请上传法人身份证；
                    <br />
                    • 个体户或企业用户上传法人身份证；
                    <br />
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(8)}
                    >
                      示例
                    </p>
                  </div>
                </div>
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title" style={{ marginRight: 38 }}>
                    开户人身份证
                    <br />
                    背面照 <i className="red">*</i>
                  </div>
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      changeData("bank_id_card_back", arr);
                    }}
                    fileList={storeInfo.bank_id_card_back}
                    disabled={!first}
                  />
                  <div className="img-tip">
                    • 上传JPG、PNG格式，图片小于1M；
                    <br />
                    • 身份证未过期；
                    <br />
                    • 对公账户请上传法人身份证；
                    <br />
                    • 个体户或企业用户上传法人身份证；
                    <br />
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(7)}
                    >
                      示例
                    </p>
                  </div>
                </div>
                {storeInfo.settle_status !== 2 && (
                  <div
                    className="category-content_wrapper"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="title" style={{ marginRight: 38 }}>
                      结算银行卡照 <i className="red">*</i>
                    </div>
                    <BusinessImg
                      OSSData={OSSData}
                      listChange={(arr) => {
                        changeData("bank_card_image", arr);
                      }}
                      onSuccess={(arr) => {
                        image2Text("bank_card_image", arr);
                      }}
                      fileList={storeInfo.bank_card_image}
                      defaultImg={require("../../assets/images/1.png")}
                      disabled={!first}
                    />
                    <div className="img-tip">
                      • 上传JPG、PNG格式，图片小于1M；
                      <br />
                      • 证件文字数字清晰可辨；
                      <br />
                      <p
                        style={{ color: "#048ad3", cursor: "pointer" }}
                        onClick={() => showExample(5)}
                      >
                        示例
                      </p>
                    </div>
                  </div>
                )}
                {/* {storeInfo.settle_status != 2 && (
                  <div
                    className="category-content_wrapper"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="title" style={{ marginRight: 38 }}>
                      手持银行卡照 <i className="red">*</i>
                    </div>
                    <BusinessImg
                      OSSData={OSSData}
                      listChange={(arr) => {
                        changeData("bank_hand_image", arr);
                      }}
                      fileList={storeInfo.bank_hand_image}
                      disabled={!first}
                    />
                    <div className="img-tip">
                      • 上传JPG、PNG格式，图片小于1M；
                      <br />
                      • 证件文字数字清晰可辨；
                      <br />• 手持身份证人脸清晰；
                      <p
                        style={{ color: "#048ad3", cursor: "pointer" }}
                        onClick={() => showExample(11)}
                      >
                        示例
                      </p>
                    </div>
                  </div>
                )} */}
                {storeInfo.settle_status == 2 && (
                  <div
                    className="category-content_wrapper"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="title" style={{ marginRight: 56 }}>
                      基本存款账户信息 <i className="red"> * </i>
                    </div>
                    <BusinessImg
                      OSSData={OSSData}
                      listChange={(arr) => {
                        changeData("opening_permit_image", arr);
                      }}
                      fileList={storeInfo.opening_permit_image}
                      disabled={!first}
                    />
                    <div className="img-tip">
                      • 上传JPG、PNG格式，图片小于1M；
                      <br />
                      • 证件文字数字清晰可辨；
                      <br />
                      <p
                        style={{ color: "#048ad3", cursor: "pointer" }}
                        onClick={() => showExample(2)}
                      >
                        示例
                      </p>
                    </div>
                  </div>
                )}

                <div className="category-content_wrapper">
                  <div className="title">
                    账户类型 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Select
                      disabled={true}
                      className="my-input"
                      value={storeInfo.settle_status == 2 ? "对公" : "对私"}
                    >
                      {bankType.map((i) => {
                        return (
                          <Option value={i.name} key={i.id}>
                            {i.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    银行卡号 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="请填写银行卡号"
                      value={storeInfo.bank_account}
                      className="my-input"
                      maxLength={30}
                      onChange={(e) =>
                        changeData("bank_account", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    银行名称 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Select
                      showSearch
                      disabled={!first}
                      className="my-input"
                      value={
                        storeInfo.bank_name
                          ? storeInfo.bank_name
                          : "请选择银行名称"
                      }
                      onChange={(e) => changeBankName(e, 1)}
                    >
                      {bankName.map((i) => {
                        return (
                          <Option value={i.name} key={i.id}>
                            {i.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div
                  className="category-content_wrapper"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="title">
                    开户支行
                    <br />
                    省市区 <i className="red">*</i>
                  </div>
                  <div className="content" style={{ flex: 1 }}>
                    <div>
                      <Select
                        disabled={!first}
                        value={
                          storeInfo.bank_province == ""
                            ? "选择省份"
                            : storeInfo.bank_province
                        }
                        style={{ width: 170, marginRight: 110, fontSize: 14 }}
                        onChange={(v) => changeAddress(v, 1)}
                      >
                        {provinceArr.map((i) => {
                          return (
                            <Option value={i.name} key={i.id}>
                              {i.name}
                            </Option>
                          );
                        })}
                      </Select>
                      <Select
                        disabled={!first}
                        value={
                          storeInfo.bank_city == ""
                            ? "选择市"
                            : storeInfo.bank_city
                        }
                        style={{ width: 170, marginRight: 110, fontSize: 14 }}
                        onChange={(v) => changeAddress(v, 2)}
                      >
                        {cityArr.map((i) => {
                          return (
                            <Option value={i.name} key={i.id}>
                              {i.name}
                            </Option>
                          );
                        })}
                      </Select>
                      <Select
                        disabled={!first}
                        value={
                          storeInfo.bank_area == ""
                            ? "选择区"
                            : storeInfo.bank_area
                        }
                        style={{ width: 170, marginRight: 110, fontSize: 14 }}
                        onChange={(v) => changeAddress(v, 3)}
                      >
                        {areaArr.map((i) => {
                          return (
                            <Option value={i.name} key={i.id}>
                              {i.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title">
                    支行名称 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Select
                      showSearch
                      disabled={!first}
                      className="my-input"
                      value={
                        storeInfo.bank_branch
                          ? storeInfo.bank_branch
                          : "请选择支行名称"
                      }
                      onChange={(e) => changeBankName(e, 2)}
                    >
                      {bankArr.map((i) => {
                        return (
                          <Option value={i.name} key={i.id}>
                            {i.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="category-content_wrapper">
                  <div className="title">
                    开户名称 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="对私填写持卡人姓名，对公填写公司名称"
                      maxLength={20}
                      value={storeInfo.bank_account_name}
                      className="my-input"
                      onChange={(e) =>
                        changeData("bank_account_name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="category-content_wrapper">
                  <div className="title" style={{ marginRight: 20 }}>
                    开户人证件号码 <i className="red">*</i>
                  </div>
                  <div className="content">
                    <Input
                      disabled={!first}
                      placeholder="请填写开户人证件号码"
                      value={storeInfo.bank_account_id_card_no}
                      className="my-input"
                      onChange={(e) =>
                        changeData("bank_account_id_card_no", e.target.value)
                      }
                    />
                  </div>
                </div>
                {storeInfo.settle_status == 1 && (
                  <div className="category-content_wrapper">
                    <div className="title" style={{ marginRight: 20 }}>
                      预留手机号 <i className="red">*</i>
                    </div>
                    <div className="content">
                      <Input
                        type="tel"
                        disabled={!first}
                        placeholder="请填写银行卡预留手机号"
                        value={storeInfo.bank_mobile}
                        className="my-input"
                        maxLength={11}
                        onChange={(e) =>
                          changeData("bank_mobile", e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <Modal
          visible={showMoreData}
          title="提交资料"
          okText="提交"
          width={900}
          onCancel={() => setMoreData(false)}
          onOk={() => upData()}
        >
          {storeBase.status == 5 && (
            <div className="category-content_wrapper">
              <div className="title" style={{ width: 140, marginRight: 36 }}>
                打款验证 <i className="red"></i>
              </div>
              <div className="content">
                <Input
                  placeholder="请您填写网商给您打款的金额进行验证"
                  value={verifyAmount}
                  className="my-input"
                  type="number"
                  style={{ width: 400 }}
                  onChange={(e) => {
                    setVerifyAmount(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          {storeBase.status == 6 && (
            <div
              className="category-content_wrapper"
              style={{ alignItems: "flex-start" }}
            >
              <div className="title" style={{ width: 110 }}>
                商户授权
                <br />
                验证 <i className="red"></i>
              </div>
              <div className="category-content_bottom-wrapper">
                <div className="qr-img">
                  <span>请法人使用微信扫描以下二维码进行商户授权验证</span>
                  <img src={require("../../assets/images/pay.png")} alt="" />
                </div>
                <div className="category-content_img-wrapper">
                  <BusinessImg
                    OSSData={OSSData}
                    listChange={(arr) => {
                      setAuthImage(arr);
                    }}
                    fileList={authImage}
                  />
                  <div className="img-tip" style={{ marginLeft: 40 }}>
                    • 请法人使用微信扫码商户授权认证成功后，上传认证成功的截图；
                    <br />• 上传JPG、PNG格式，图片小于1M；
                    <p
                      style={{ color: "#048ad3", cursor: "pointer" }}
                      onClick={() => showExample(10)}
                    >
                      示例
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>

        <div className="confirm-wrapper">
          {step !== 0 && (
            <div
              className="confirm-prev"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              上一步
            </div>
          )}
          <div
            className="confirm-next"
            style={{
              backgroundColor:
                step == 3 && !first ? "rgba(244,152,29,0.6)" : "#F4981D",
            }}
            onClick={() => submit()}
          >
            {step == 3 ? "提交" : "下一步"}
          </div>
        </div>
      </div>
      <div className="index-contact">
        <Centers history={history} title={"公告通知"} type={3} />
        <Contact />
        <Information />
        <Client />
        <Centers history={history} title={"学习中心"} type={2} />
      </div>
    </div>
  );
};

export default BasicInfo;
