import React, { useState, useEffect } from "react";
import { USER, UPDATE_DOWNLOAD } from "@/graphql/store";
import { Button, Modal } from "antd";
import "./index.less";
import client from "@/graphql/apollo-config";
import app from "@/app.json";
import qs from "query-string";
import Cookies from "js-cookie";

const bgImg = require("@/assets/images/wechat.png");

const QRCode = require("qrcode-react");

function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

const Download = (props) => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [id, setId] = useState();
  const [isDownload, setDownload] = useState(3); //0店铺基本信息, 1设置典金计划, 2通过

  const { history } = props;

  const { id: _id } = qs.parse(history.location.search);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    try {
      const { data } = await client.query({
        query: USER,
        fetchPolicy: "no-cache",
      });
      if (data) {
        setId(_id || data.user.store.pos_code);
        if (
          data.storeInfo.store_base_audit &&
          data.storeInfo.store_base_audit.is_first_audit
        ) {
          setDownload(0);
        } else if (!data.user.store.set_commission_rule) {
          setDownload(1);
        } else if (!data.user.store.pos_code) {
          setDownload(2);
        } else {
          drawAndShareImage();
        }
      }
    } catch (error) {}
  };

  const drawAndShareImage = () => {
    const canvas = document.createElement("canvas");
    const canvas2 = document.querySelector(".qr-code > canvas");
    canvas.width = 400;
    canvas.height = 641;
    const context = canvas.getContext("2d");

    context.rect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#fff";
    context.fill();

    const myImage = new Image();
    myImage.src = bgImg; //背景图片  你自己本地的图片或者在线图片
    myImage.crossOrigin = "Anonymous";

    myImage.onload = function () {
      context.drawImage(myImage, 0, 0, 400, 641);
      context.textAlign = 'center'
      context.textBaseline = 'middle'
      context.font = '21px bold'
      context.fillStyle = '#000000'
      context.fillText(Cookies.get("store_name"), 200, 192)

      const myImage2 = new Image();
      myImage2.src = canvas2.toDataURL(); //你自己本地的图片或者在线图片
      myImage2.crossOrigin = "Anonymous";
      myImage2.onload = function () {
        context.drawImage(myImage2, 100, 220, 200, 200);
        const base64 = canvas.toDataURL("image/png"); //"image/png" 这里注意一下
        const img = document.getElementById("avatar");
        img.setAttribute("src", base64);
        setFile(base64);
        setFileName("qrCode.png");
      };
    };
  };

  const download = async () => {
    try {
      const { data } = await client.query({
        query: UPDATE_DOWNLOAD,
        fetchPolicy: "no-cache",
      });
      console.log("data: ", data);
    } catch (error) {}

    if (IsPC()) {
    } else {
      Modal.info({
        icon: null,
        content: "请长按图片，保存图片至手机相册",
        width: 600,
      });
    }
  };

  return (
    <div className="download-wrapper">
      <div className="download-tip">
        {isDownload === 0 && "请先提交店铺基本信息"}
        {isDownload === 1 && <div>
          <span>请先设置赏金计划</span>
          <Button type="primary" style={{ marginLeft: '14px' }} onClick={() => {
            history.push('/commission')
          }}>去设置</Button>
        </div>}
        {isDownload === 2 && "请先绑定收款码！如无收款码，请联系客服或者业务员。"}
      </div>
      {isDownload === 3 && (
        <div>
          <img id="avatar" alt="" />
          <div className="qr-code">
            <QRCode
              value={`${app.host}/pay?id=${id}`}
              size={300}
            />
          </div>
          <a
            href={file}
            download={fileName}
            id="download"
            onClick={() => download()}
          >
            保存图片
          </a>
        </div>
      )}
    </div>
  );
};

export default Download;
