import React, { useMemo, useReducer, useState, useEffect } from "react";
import { Button, Card, Row, Col, Input, Table, Modal, Message } from "antd";
import Contact from "../../components/public/contact/contact";
import Centers from "../../components/public/contact/center";
import Client from "../../components/public/contact/client";
import Information from "../../components/public/contact/information";
import QrcodeView from "../../components/public/contact/qrcodeView";
import StoreQrcodeView from "../../components/public/contact/storeQrcodeView";
import IndexCard from "../../components/store/indexCard";
import TipsModal from "../../components/modal/tips/tipsModal";
import "./index.less";
import Cookies from "js-cookie";
import { useQuery, useLazyQuery, useMutation } from "react-apollo";
import { WITH_DRAW, ADD_WITH_DRAW } from "../../graphql/finance";
import { STORE_TASKS } from "../../graphql/store";
import { USER } from "../../graphql/router";
import moment from "moment";
import BigNumber from "bignumber.js";
import client from "../../graphql/apollo-config";
import app from "@/app.json";

const columns = [
  {
    title: "提现时间",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "类型",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "结算金额（¥）",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "到账状态",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "操作",
    dataIndex: "handle",
    key: "handle",
    fixed: "right",
  },
];

// 处理时间
function getDate(date) {
  if (date === 0) return "-";
  return moment(date * 1000).format("YYYY-MM-DD HH:mm:ss");
}

const initState = {
  page: 1,
  limit: 10,
  visible: false,
  showTips: false,
  amount: "",
};

const financeReducer = (state, { type, payload }) => {
  switch (type) {
    case "setVisible":
      return {
        ...state,
        visible: payload.visible,
      };
    case "setShowTips":
      return {
        ...state,
        showTips: payload.showTips,
      };
    case "setPage":
      return {
        ...state,
        page: payload.page,
      };
    case "setAddWithDraw":
      return {
        ...state,
        amount: payload.amount,
      };
    default:
      return state;
  }
};

const Finance = (props) => {
  const [state, dispatch] = useReducer(financeReducer, initState);
  const [data2, setData2] = useState({});
  const { page, limit, visible, showTips } = state;

  useEffect(() => {
    if (props.storeId) {
      getData();
    }
  }, [props.storeId]);

  const [getData] = useLazyQuery(WITH_DRAW, {
    fetchPolicy: "no-cache",
    variables: {
      limit: limit,
      page: page,
    },
    onCompleted: (data) => {
      if (data) {
        setData2(data);
      }
    },
    onError: () => {},
  });

  const [addWithdraw0] = useMutation(ADD_WITH_DRAW, {
    variables: {
      amount: data2 && data2.user && data2.user.can_commission,
    },
    onCompleted: (data) => {
      if (data.addWithdraw) {
        Message.info("提现成功");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },
    onError: (err) => {
      Message.err(err.message);
    },
  });

  const onChange = (page, pageSize) => {
    dispatch({ type: "setPage", payload: { page: page } });
  };

  const dataSource = useMemo(() => {
    if (data2.withdraws) {
      return data2.withdraws.data.map((i, key) => {
        return {
          key: key + "",
          ...i,
          amount_origin: BigNumber(i.amount).plus(i.service_charge),
          amount: i.amount,
          status:
            i.status === 3 ? "自动到账" : i.status === 0 ? "待到账" : "已到账",
          type: i.status === 3 ? "扫码付款订单" : "提现金额",
          created_at: getDate(i.created_at),
        };
      });
    }
    return [];
  }, [data2]);

  const current = useMemo(
    () => (data2.withdraws && data2.withdraws.current_page) || 1,
    [data2]
  );
  const total = useMemo(
    () => (data2.withdraws && data2.withdraws.total) || 0,
    [data2]
  );
  const isNeedBD = useMemo(
    () => data2.bankCard && data2.bankCard.bank_account === "",
    [data2]
  );

  const today_turnover_qr = data2 && data2.user && data2.user.today_turnover_qr;
  const total_turnover_qr = data2 && data2.user && data2.user.total_turnover_qr;
  const today_turnover = data2 && data2.user && data2.user.today_turnover;
  const total_turnover = data2 && data2.user && data2.user.total_turnover;
  const today_commission = data2 && data2.user && data2.user.today_commission;
  const total_commission = data2 && data2.user && data2.user.total_commission;
  const today_hongbao_subsidy =
    data2 && data2.user && data2.user.today_hongbao_subsidy;
  const total_hongbao_subsidy =
    data2 && data2.user && data2.user.total_hongbao_subsidy;

  const store_commission_withdraw =
    data2 && data2.user && data2.user.store_commission_withdraw;
  const commission_today_is_withdraw =
    data2 && data2.user && data2.user.commission_today_is_withdraw;
  const can_commission = data2 && data2.user && data2.user.can_commission;
  const pending_commission_withdraw =
    data2 && data2.user && data2.user.pending_commission_withdraw;

  const handleRender = (_, record) => <Detail record={record} />;
  const cols = columns.map((col) => {
    if (col.key != "created_at") {
      col.align = "center";
    }

    if (col.key === "handle") {
      col.render = handleRender;
    }
    return col;
  });

  const TableProps = {
    dataSource: dataSource,
    columns: cols,
    tableLayout: "auto",
    bordered: true,
    pagination: {
      position: ["bottomCenter"],
      defaultCurrent: 1,
      defaultPageSize: 10,
      // current: current,
      // total: total,
      // onChange: onChange,
      // showSizeChanger: false,
      // showQuickJumper: true
    },
  };
  return (
    <div className="finace">
      <div className="finace-container">
        <IndexCard
          topTitle={"扫码付款-营业额收入"}
          topTip={"注：无需申请提现，T+1自动到账网商绑定的银行账号"}
          firstTitle={"今日营业额"}
          secondTitle={"累计营业额"}
          firstMoney={today_turnover_qr}
          secondMoney={total_turnover_qr}
        />
        <div className="finace-bd" />
        <IndexCard
          topTitle={"线上商城-营业额收入"}
          topTip={"注：需申请提现，T+1到账网商绑定的银行账号"}
          firstTitle={"今日营业额"}
          secondTitle={"累计营业额"}
          firstMoney={today_turnover}
          secondMoney={total_turnover}
        />
      </div>
      <div className="finace-container">
        <IndexCard
          topTitle={"赏金计划收入"}
          topTip={"注：需申请提现，T+1到账网商绑定的银行账号"}
          firstTitle={"今日赏金计划收入"}
          secondTitle={"累计赏金计划收入"}
          firstMoney={today_commission}
          secondMoney={total_commission}
        />
        <div className="finace-bd" />
        <IndexCard
          topTitle={"红包补贴"}
          topTip={"注：需申请提现，T+1到账网商绑定的银行账号"}
          firstTitle={"今日红包补贴"}
          secondTitle={"累计红包补贴"}
          firstMoney={today_hongbao_subsidy}
          secondMoney={total_hongbao_subsidy}
        />
      </div>

      <div className="finace-wrapper finace-wrapper2">
        <FTitle
          title={"提现管理"}
          subTitle={
            store_commission_withdraw == 0
              ? "免提现手续费"
              : `提现每笔需扣${
                  store_commission_withdraw ? store_commission_withdraw : "-"
                }元提现手续费`
          }
        />
        <div className="finace-tip">
          注：以下是线上商城-营业额收入、赏金计划收入、红包补贴合计金额
        </div>
        <div className="finace-top">
          <span className="flex-span">
            <div className="finace-card">
              <p>可提现金额</p>
              <h2>
                <span>¥</span>
                {can_commission}
              </h2>
            </div>
          </span>
          <span className="flex-span">
            <div className="finace-card">
              <p>提现待到账金额</p>
              <h2>
                <span>¥</span>
                {pending_commission_withdraw}
              </h2>
            </div>
          </span>
        </div>
        <Button
          type="primary"
          className="finace-button"
          disabled={BigNumber(can_commission) < 100}
          onClick={() => {
            console.log(isNeedBD);
            dispatch({ type: "setVisible", payload: { visible: true } });
            // if (isNeedBD) {
            //   // dispatch({ type: "setShowTips", payload: { showTips: true } });
            // } else {
            //   dispatch({ type: "setVisible", payload: { visible: true } });
            // }
          }}
        >
          {BigNumber(can_commission) < 100 ? "满¥100可提现" : "提现"}
        </Button>
      </div>

      <Card className="finace-wrapper">
        <h1>提现记录</h1>
        <Table {...TableProps} />
      </Card>

      <Modal
        visible={visible}
        onOk={() => {
          if (commission_today_is_withdraw) {
            Message.info("每天只能提现一次");
          } else {
            addWithdraw0();
          }
          dispatch({ type: "setVisible", payload: { visible: false } });
        }}
        onCancel={() =>
          dispatch({ type: "setVisible", payload: { visible: false } })
        }
      >
        <div className="details">
          <h1>结算金额</h1>
          <h1>{can_commission - store_commission_withdraw}元</h1>
          {can_commission != 0 && (
            <>
              <Row>
                <Col span={8}>原提现金额</Col>
                <Col span={8} offset={8}>
                  {can_commission}
                </Col>
              </Row>
              <Row>
                <Col span={8}>扣手续费</Col>
                <Col span={8} offset={8}>
                  {store_commission_withdraw != 0
                    ? `${"-" + store_commission_withdraw}元`
                    : "免手续费"}
                </Col>
              </Row>
            </>
          )}
          <p>
            {store_commission_withdraw == 0
              ? "免提现手续费"
              : `提现每笔需扣${
                  store_commission_withdraw ? store_commission_withdraw : "-"
                }元提现手续费`}
          </p>
        </div>
      </Modal>
      <TipsModal
        title={"补充认证资料"}
        content={"提现需要补充认证资料，是否立即去补充？"}
        cancelText={"暂不"}
        okText={"去补充"}
        showTips={showTips}
        onOk={() => {
          dispatch({ type: "setShowTips", payload: { showTips: false } });
          props.history.push(`/basicInfo`);
        }}
        onCancel={() =>
          dispatch({ type: "setShowTips", payload: { showTips: false } })
        }
      />
    </div>
  );
};

const FTitle = ({ title, subTitle }) => {
  return (
    <div className="finace-title-wrapper">
      <span className="finace-title">{title}</span>
      {subTitle && (
        <>
          <svg
            t="1595990686915"
            className="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2476"
          >
            <path
              d="M512 832c-176.448 0-320-143.552-320-320 0-104.384 50.464-196.928 128-255.392A318.016 318.016 0 0 1 512 192c72.09600001 0 138.432 24.256 192 64.608 77.536 58.464 128 151.008 128 255.392 0 176.448-143.552 320-320 320m192-652.032A381.216 381.216 0 0 0 512 127.99999999c-69.98399999 0-135.424 19.136-192 51.96800001C205.408 246.496 128 370.23999999 127.99999999 512c0 211.74400001 172.256 384.00000001 384.00000001 384.00000001s384.00000001-172.256 384.00000001-384.00000001c0-141.76-77.408-265.504-192.00000001-332.032"
              fill="#666666"
              p-id="2477"
            ></path>
            <path
              d="M512 416.00000001a32 32 0 0 0-32 31.99999999l0 256a32 32 0 0 0 64 0l0-256a32 32 0 0 0-32-31.99999999M512 287.99999999a32 32 0 1 0 0 64.00000001 32 32 0 0 0 0-64.00000001"
              fill="#666666"
              p-id="2478"
            ></path>
          </svg>
          <span className="finace-sub-title">{subTitle}</span>
        </>
      )}
    </div>
  );
};

const Detail = ({ record }) => {
  const [visible, setVisible] = useState(false);
  return (
    <span>
      <a onClick={() => setVisible(true)}>详情</a>
      <Modal
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setVisible(false)}>
            确定
          </Button>,
        ]}
      >
        <div className="details">
          <h1>详情</h1>
          <Row>
            <Col span={8}>原提现金额</Col>
            <Col span={8} offset={8}>{`${record.amount_origin}元`}</Col>
          </Row>
          <Row>
            <Col span={8}>扣手续费</Col>
            <Col span={8} offset={8}>{`${
              record.service_charge == 0 ? 0 : "-" + record.service_charge
            }元`}</Col>
          </Row>
          <Row>
            <Col span={8}>结算金额</Col>
            <Col span={8} offset={8}>{`${record.amount}元`}</Col>
          </Row>
          <p>
            {record.service_charge != 0
              ? `提现每笔需扣${record.service_charge}元提现手续费`
              : `免提现手续费`}
          </p>
        </div>
      </Modal>
    </span>
  );
};

const Index = (props) => {
  const history = props.history;
  const [info, setInfo] = useState([]);
  const [info2, setInfo2] = useState([]);
  const [show, setShow] = useState(true);
  const [storeId, setStoreId] = useState(null);
  const [repacket, setRepacket] = useState({
    endAt: "",
    isJoin: false,
    banStatus: false,
  });

  useEffect(() => {
    getStoreId();
  }, []);

  const getStoreId = async () => {
    try {
      const { data } = await client.query({
        query: USER,
        fetchPolicy: "no-cache",
      });
      if (data) {
        const id = data.user.store && data.user.store.id;
        init(id);
        if (id) {
          setStoreId(data.user.store.id);
        }
      }
    } catch (error) {}
  };

  const init = async (id) => {
    console.log(id, 333);
    let tasks = "";
    if (id) {
      try {
        const { data } = await client.query({
          query: STORE_TASKS,
          fetchPolicy: "no-cache",
        });
        if (data) {
          const store = data.storeInfo;
          tasks = store.tasks;
          setRepacket({
            endAt: store.hongbao_activity_end_at,
            isJoin: store.is_hongbao,
            banStatus: store.hongbao_ban_status,
          });
        }
      } catch (error) {}
    }
    setInfo([
      {
        icon: !id
          ? false
          : tasks.find((i) => {
              return i == 1;
            }),
        text: "01 获取收益从填写入驻信息开始",
        url: "/joinDz",
        urlText: "立即填写",
      },
      {
        icon: !id
          ? false
          : tasks.find((i) => {
              return i == 3;
            }),
        text: "03 下载收款码对用户收款",
        url: "/download",
        urlText: "立即下载",
      },
    ]);
    setInfo2([
      // {
      //   icon: !id
      //     ? false
      //     : tasks.find((i) => {
      //         return i == 1;
      //       }),
      //   text: "01 获取收益从填写入驻信息开始",
      //   url: "/joinDz",
      //   urlText: "立即填写",
      // },
      // {
      //   icon: !id?false:tasks.find(i => { return i == 5 }),
      //   text:'02 线上开店认证',
      //   url: '/store',
      //   urlText:'立即认证'
      // },
      {
        icon: !id
          ? false
          : tasks.find((i) => {
              return i == 2;
            }),
        text: "02 填写店铺让利赏金计划比例",
        url: "/commissionDetails",
        urlText: "立即填写",
      },
      {
        icon: !id
          ? false
          : tasks.find((i) => {
              return i == 6;
            }),
        text: "04 店铺上架商品，开启营业",
        url: "/category",
        urlText: "立即开启",
      },
    ]);
  };

  const goRec = (type) => {
    if (type == 1) {
      history.push(`/index/explain?name=hongbao_store_explain`);
    } else if (type == 2) {
      if (repacket.banStatus == 0) {
        history.push(`/index/explain?name=hongbao_agreement`);
      } else {
        Message.info("您还未获得参加资格，如有疑问请联系客服");
      }
    } else {
      // 跳到记录页
      history.push(`/giftRecome`);
    }
  };

  const go = (url, index) => {
    if (!storeId && index !== 0) {
      Message.info("请先填写店铺基本信息");
    } else {
      history.push(url);
    }
  };

  return (
    <div className="index-container">
      <div className="index-wrapper">
        <div className="index-top">
          <img src={require("@/assets/images/top2.png")} className="top2" />
          {show && (
            <>
              <img src={require("@/assets/images/top.png")} className="top1" />
              <img src={require("@/assets/images/top3.png")} className="top3" />
            </>
          )}
          <div className="index-top_title">
            <div className="index-top_left">新手任务：3分钟开启商业增值</div>
            <div onClick={() => setShow(!show)} className="index-top_more">
              {show ? "收起" : "展开"}
              <i
                className="iconfont"
                style={{ transform: show ? "rotate(270deg)" : "rotate(90deg)" }}
              >
                &#xe6f8;
              </i>
            </div>
          </div>
          {show && (
            <div className="index-top_content">
              <div className="index-top_content-main">
                <div className="index-top_content-wrapper">
                  <div className="index-top_content-title">典栈生活圈收款码</div>
                  <div className="index-top_content-text">
                    用于线下向用户展示商家的收款码进行收款，扫码登录即与用户锁定关系，终身获取用户消费收益。
                  </div>
                </div>
                <ul className="steps">
                  {info.map((i, index) => {
                    return (
                      <li key={index + ""}>
                        {i.icon ? (
                          <i className="iconfont" style={{ color: "#5ED949" }}>
                            &#xe6da;
                          </i>
                        ) : (
                          <i className="iconfont" style={{ color: "#EF5656" }}>
                            &#xe668;
                          </i>
                        )}
                        <span className="text">{i.text}</span>
                        {!i.icon && (
                          <span
                            className="tip2"
                            onClick={() => go(i.url, index)}
                          >
                            {i.urlText}
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="index-top_content-main">
                <div className="index-top_content-wrapper">
                  <div className="index-top_content-title">线上开店</div>
                  <div className="index-top_content-text">
                    用于在典栈生活圈小程序开店，用户浏览店铺即与用户锁定关系，终身获取用户消费收益。
                  </div>
                </div>
                <ul className="steps steps2">
                  {info2.map((i, index) => {
                    return (
                      <li key={index + ""}>
                        {i.icon ? (
                          <i className="iconfont" style={{ color: "#5ED949" }}>
                            &#xe6da;
                          </i>
                        ) : (
                          <i className="iconfont" style={{ color: "#EF5656" }}>
                            &#xe668;
                          </i>
                        )}
                        <span className="text">{i.text}</span>
                        {!i.icon && (
                          <span
                            className="tip2"
                            onClick={() => go(i.url, index)}
                          >
                            {i.urlText}
                          </span>
                        )}
                        {index == 4 && i.icon && (
                          <span
                            className="tip2"
                            onClick={() => history.push("/index/connect")}
                          >
                            查看银行卡信息
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="red-packet">
          <div className="red-packet_left">
            <div>
              <span className="title">收银设备</span>
            </div>
            <div className="red-packet_left-bottom">
              <img src={require('../../assets/images/terminal.png')} style={{ width: 56, height: 56 }} />
              <div className="red-packet_text" style={{ marginLeft: 13 }}>
                <span className="red-packet_text1">自助收银机</span>
                <span className="red-packet_text2">请打开自助收银机，开始体验自助收款</span>
              </div>
            </div>
          </div>
          <div className="red-packet_right">
            <div></div>
            <Button
              type="primary"
              className="red-packet_right-text2"
              onClick={() => {
                window.open(`${app.shop_host}?token=${Cookies.get('token')}`, '_blank')
              }}
            >
              打开
            </Button>
          </div>
        </div>
        {/* <div className="red-packet">
          <div className="red-packet_left">
            <div>
              <span className="title">营销活动</span>
              {repacket.isJoin == 1 && (
                <span className="title2">
                  进行中，活动将于{getDate(repacket.endAt)}结束
                </span>
              )}
            </div>
            <div className="red-packet_left-bottom">
              <i className="iconfont" style={{ color: "#EF5555" }}>
                &#xe688;
              </i>
              <div className="red-packet_text">
                <span className="red-packet_text1">返红包</span>
                <span className="red-packet_text2">
                  下单返现金红包，提升客户消费意愿
                </span>
              </div>
            </div>
          </div>
          <div className="red-packet_right">
            <span className="red-packet_right-text1" onClick={() => goRec(1)}>
              说明
            </span>
            {storeId && (
              <Button
                type="primary"
                className="red-packet_right-text2"
                onClick={() => (repacket.isJoin == 1 ? goRec(3) : goRec(2))}
              >
                {repacket.isJoin == 1 ? "查看数据" : "报名参加"}
              </Button>
            )}
          </div>
        </div> */}
        <Finance storeId={storeId} history={history} />
      </div>
      <div className="index-contact">
        <Centers history={history} title={"公告通知"} type={3} />
        <Contact />
        <Information />
        <Client />
        <QrcodeView id={storeId} storeid={storeId} title="扫码进入我的店铺" />
        {!!storeId && <StoreQrcodeView id={storeId} />}
        <Centers history={history} title={"学习中心"} type={2} />
      </div>
    </div>
  );
};

export default Index;
