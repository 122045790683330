import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Form, Input, message, Radio, Select, Space, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import ProductSelector2 from '@/components/productSelector2'
import client from '@/graphql/apollo-config'
import { OSS } from '@/graphql/store'
import Cookies from 'js-cookie'
import moment from 'moment'
import qs from 'query-string'
import md5 from 'md5'
import app from '@/app.json'
import { activityAdd, getActivityDetail, getCouponList, getTypeList } from '@/apis/activity'
import './index.less'

const ActivityAdd = (props) => {
    const { history } = props;
    const { id } = qs.parse(history.location.search)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [OSSData, setOSSData] = useState({})
    const [typeOptions, setTypeOptions] = useState([])
    const [couponOptions, setCouponOptions] = useState([])

    useEffect(() => {
        if (id) {
            getData()
        }
        getOSSData()
        getTypeList().then(({ data }) => {
            setTypeOptions(data.map(t => ({
                value: t.id,
                label: t.type_name
            })))
        })
        getCouponList({ page: 1, limit: 100 }).then(({ data }) => {
            setCouponOptions(data.data.map(t => ({
                value: t.numeral_coupon_id,
                label: t.numeral_coupon_name
            })))
        })
    }, [])

    const getOSSData = async() => {
        try {
            const { data } = await client.query({
                query: OSS,
                fetchPolicy: "no-cache",
            })
            setOSSData({
                ...data.oss
            })
        } catch (e) {
            console.log(e)
        }
    }

    const getData = async() => {
        try {
            const { data } = await getActivityDetail({ id })
            form.setFieldsValue({
                cover: data.cover,
                video_url: data.video_url,
                title: data.title,
                des: data.des,
                type_id: data.type_id,
                dates: [moment(data.start_time, 'YYYY-MM-DD'), moment(data.end_time, 'YYYY-MM-DD')],
                product_arr: data.activity_product_list,
                is_join: data.is_join
            })
        } catch (e) {
            console.log(e)
        }
    }

    const beforeUpload = (file) => {
        if (!OSSData) return false;
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
            return false
        }
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
    
        file.uid = md5(
            `${file.name}${new Date().getTime()}${Cookies.get('store_id') || 0}`
        );
        file.url = OSSData.dir + file.uid + suffix
        return file;
    }
    const getExtraData = (file) => {
        return {
            key: file.url,
            OSSAccessKeyId: OSSData.accessid,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    }
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false)
            form.setFieldsValue({
                cover: app.oss_host + info.file.url
            })
        }
    }

    const beforeUpload2 = (file) => {
        if (!OSSData) return false;
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        file.uid = md5(
            `${file.name}${new Date().getTime()}${Cookies.get('store_id') || 0}`
        );
        file.url = OSSData.dir + file.uid + suffix
        return file;
    }
    const handleChange2 = (info) => {
        if (info.file.status === 'uploading') {
            setLoading2(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading2(false)
            form.setFieldsValue({
                video_url: app.oss_host + info.file.url
            })
        }
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    const onSubmit = async(values) => {
        try {
            const params = {
                cover: values.cover,
                video_url: values.video_url,
                title: values.title,
                des: values.des,
                type_id: values.type_id,
                start_time: values.dates[0].format('YYYY-MM-DD'),
                end_time: values.dates[1].format('YYYY-MM-DD'),
                numeral_coupon_arr: JSON.stringify(
                    couponOptions.filter(t => values.numeral_coupon_arr.includes(t.value)).map(t => ({
                        numeral_coupon_id: t.value,
                        numeral_coupon_name: t.label
                    }))
                ),
                product_arr: JSON.stringify(values.product_arr),
                is_join: values.is_join
            }
            if (id) {
                params.id = id
            }
            await activityAdd(params)
            message.success(params.id ? '编辑成功' : '提交成功');
            setTimeout(() => {
                history.go(-1);
            }, 2000);
        } catch (e) {
            console.log(e)
        }
    }


    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>封面</div>
        </div>
    )
    const uploadButton2 = (
        <div>
            {loading2 ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>视频</div>
        </div>
    )
    return (
        <div className="activityAdd">
            <Form form={form} colon={false} autoComplete="off" onFinish={onSubmit}>
                {(data) => (<>
                    <Form.Item label="活动封面" name="cover" rules={[{ required: true, message: '请上传封面' }]}>
                        <div>
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                accept="image/*"
                                action={`https://${OSSData.host}`}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                data={(file) => getExtraData(file)}
                            >
                                {data.cover ? (
                                    <img
                                        src={data.cover}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </div>
                    </Form.Item>
                    <Form.Item label="活动视频" name="video_url" rules={[{ required: true, message: '请上传视频' }]}>
                        <div>
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                accept="video/*"
                                action={`https://${OSSData.host}`}
                                beforeUpload={beforeUpload2}
                                onChange={handleChange2}
                                data={(file) => getExtraData(file)}
                            >
                                {data.video_url ? (
                                    <img
                                        src={data.video_url + '?x-oss-process=video/snapshot,t_1050,f_jpg'}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton2
                                )}
                            </Upload>
                        </div>
                    </Form.Item>
                    <Form.Item label="活动名称" name="title" rules={[{ required: true, message: '请输入活动名称' }]}>
                        <Input style={{ width: 300 }} />
                    </Form.Item>
                    <Form.Item label="活动介绍" name="des" rules={[{ required: true, message: '请输入活动介绍' }]}>
                        <Input.TextArea rows={4} style={{ width: 300 }} />
                    </Form.Item>
                    <Form.Item label="活动类型" name="type_id" rules={[{ required: true, message: '请选择活动类型' }]}>
                        <Select
                            style={{ width: 300 }}
                            placeholder="请选择"
                            options={typeOptions}
                        />
                    </Form.Item>
                    <Form.Item label="活动时间" name="dates" rules={[{ required: true, message: '请选择活动时间' }]}>
                        <DatePicker.RangePicker disabledDate={disabledDate} style={{ width: 300 }} />
                    </Form.Item>
                    <Form.Item label="活动设置" required>
                        <Space>
                            <Form.Item name="numeral_coupon_arr" initialValue={[]} rules={[{ required: true, message: '请选择数字券' }]} noStyle>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    placeholder="请选择数字券"
                                    options={couponOptions}
                                    style={{
                                        width: 300,
                                    }}
                                />
                            </Form.Item>
                            <Button type="link" style={{ padding: 0 }} onClick={() => history.push('/numeralCoupon/add')}>新增数字券</Button>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        label="选择商品"
                        name="product_arr"
                        initialValue={[]}
                        rules={[{ required: true, message: '请选择商品' }]}
                    >
                        <ProductSelector2 mode="multiple" />
                    </Form.Item>
                    <Form.Item label="活动商家" name="is_join" rules={[{ required: true, message: '请选择活动商家' }]}>
                        <Radio.Group>
                            <Radio value={1}>允许其他商家加入</Radio>
                            <Radio value={0}>不允许其他商家加入</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label=" ">
                        <Button
                            onClick={() => window.history.back()}
                            style={{
                                marginRight: 10,
                            }}
                        >
                            取消
                        </Button>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </>)}
            </Form>
        </div>
    )
}

export default ActivityAdd